import {
  AutocompleteElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { IconButton, InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CropperModal } from '../../Common/ImageCropper';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import Loading from 'react-fullscreen-loading';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { callAxios } from '../../../utils/axios';
import { countries } from '../../../constants/countries';
import { debounce } from '@mui/material/utils';
import parse from 'autosuggest-highlight/parse';
import { styled } from '@mui/material/styles';
import { toastMessage } from '../../../utils/toast';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const GOOGLE_MAPS_API_KEY = 'AIzaSyC1jYctLwdiesL_sAaQh8QGTkXPi7-S03M';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}
const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

type FormProps = {
  centerName: string;
  fullname: string;
  countryCode: string;
  phone: number;
  email: string;
  address: string;
  password: string;
  confirmPassword: string;
  about: string;
};

const Input = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: '8px',
    position: 'relative',
    backgroundColor: '#F9F9FC',
    fontFamily: 'Public Sans',
    border: '1px solid #E0E2E7',
    paddingLeft: '10px',
    fontSize: '14px',
  },
}));

const styles = {
  root: {
    '& .MuiInputBase-input:-webkit-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input:-moz-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::-ms-input-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    backgroundColor: '#F9F9FC',
    borderRadius: '8px',
    color: '#858D9D !important',
  },
};

const AdoptionDetails = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);
  const [defaultValues, setDefaultValues] = useState(null);

  const [src, setSrc] = useState(null);
  const [preview, setPreview] = useState(null);
  const [image, setImage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  // handle Change
  const handleImgChange = (e) => {
    setSrc(URL.createObjectURL(e.target.files[0]));
    setModalOpen(true);
  };

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        400
      ),
    []
  );
  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions.slice(0, 3));
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const getUser = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/dashUser/getUserById?id=${searchParams.get(
          'userId'
        )}&userType=${searchParams.get('userType')}`
      );
      console.log(data);
      const values = { ...data?.data[0] };
      values.password = '';
      setDefaultValues(values);
      setInputValue(data?.data[0]?.address);
      setValue({
        description: data?.data[0]?.address,
        structured_formatting: {
          main_text: data?.data[0]?.address,
          secondary_text: '',
        },
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong.',
        'error'
      );
    }
  };

  useEffect(() => {
    if (searchParams.get('userId') && searchParams.get('userType')) {
      getUser();
    }
  }, []);

  const onSubmit = async (values: FormProps) => {
    console.log(values);
    if (!value) {
      return toastMessage('Please select a location', 'error');
    }

    try {
      setLoading(true);
      let result;
      if (image) {
        const formData = new FormData();
        formData.append('file', image);
        result = await callAxios('post', 'app/user/uploadImage', formData);
        console.log(result);
      }

      var config = {
        method: 'get',
        url: `https://maps.googleapis.com/maps/api/geocode/json?address=${value.description}&key=${GOOGLE_MAPS_API_KEY}`,
        headers: {},
      };
      const { data: geocode } = await axios(config);
      console.log(geocode);
      const lat = geocode.results[0].geometry.location.lat;
      const lng = geocode.results[0].geometry.location.lng;

      console.log(lat, lng);
      console.log(result?.data?.url);

      const payload: any = {
        ...values,
        address: value.description,
        latitude: lat,
        longitude: lng,
        location: {
          latitude: lat,
          longitude: lng,
        },
        userType: 'ADOPTION',
        profilePicture: image
          ? result?.data?.url
          : defaultValues?.profilePicture,
      };

      const { data } = await callAxios(
        'put',
        `dashboard/dashUser/updateAccount?id=${searchParams.get('userId')}`,
        payload
      );
      console.log(data);
      setLoading(false);
      toastMessage('Account Updated Successfully', 'success');
      navigate('/admin/user-management');
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong.',
        'error'
      );
    }
  };

  console.log(defaultValues);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      {defaultValues && (
        <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
          <div className="w-full bg-white rounded-lg shadow p-4 flex flex-col gap-4">
            <h6 className="text-tertiary publicSans text-base font-semibold ">
              Adoption Center
            </h6>
            <div className="w-full grid grid-cols-2 gap-4">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Adoption Center Name
                </p>
                <TextFieldElement
                  name="centerName"
                  fullWidth
                  size="small"
                  placeholder="Center Name"
                  sx={styles.root}
                  required
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Owner Name
                </p>
                <TextFieldElement
                  name="fullname"
                  fullWidth
                  size="small"
                  placeholder="Owner Full Name"
                  sx={styles.root}
                  required
                />
              </div>
            </div>
            <div className="w-full grid grid-cols-3 gap-4">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Email
                </p>
                <TextFieldElement
                  name="email"
                  fullWidth
                  size="small"
                  disabled
                  placeholder="Email"
                  sx={styles.root}
                  type="email"
                />
              </div>

              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Country Code
                </p>
                <SelectElement
                  options={countries.map((country) => {
                    return {
                      id: country.phone,
                      label: `${country.label} - (${country.code})`,
                    };
                  })}
                  name="countryCode"
                  fullWidth
                  size="small"
                  placeholder="Country Code"
                  sx={styles.root}
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Phone
                </p>
                <TextFieldElement
                  name="phone"
                  placeholder="Enter Phone Number"
                  type="number"
                  required
                  size="small"
                  sx={styles.root}
                  fullWidth
                  validation={{
                    validate: (value) => {
                      let regex = /^[1-9]\d+$/;
                      if (regex.test(value)) {
                        return true;
                      } else {
                        return 'The phone number should not start with zero. Please enter a valid phone number';
                      }
                    },
                  }}
                />
              </div>
            </div>
            <div className="w-full flex flex-col gap-1 ">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                About
              </p>
              <TextFieldElement
                name="about"
                fullWidth
                size="small"
                placeholder="Enter Description"
                sx={styles.root}
                multiline
                rows={3}
              />
            </div>
            <div className="w-full flex flex-col gap-1 ">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                Location
              </p>

              <Autocomplete
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.description
                }
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                fullWidth
                noOptionsText="No locations"
                size="small"
                onChange={(event: any, newValue: PlaceType | null) => {
                  setOptions(newValue ? [newValue, ...options] : options);
                  setValue(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth sx={styles.root} required />
                )}
                renderOption={(props, option) => {
                  const matches =
                    option.structured_formatting.main_text_matched_substrings ||
                    [];

                  const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: any) => [
                      match.offset,
                      match.offset + match.length,
                    ])
                  );

                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid item sx={{ display: 'flex', width: 44 }}>
                          <LocationOnIcon sx={{ color: 'text.secondary' }} />
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: 'calc(100% - 44px)',
                            wordWrap: 'break-word',
                          }}
                        >
                          {parts.slice(0, 3).map((part, index) => (
                            <Box
                              key={index}
                              component="span"
                              sx={{
                                fontWeight: part.highlight ? 'bold' : 'regular',
                              }}
                            >
                              {part.text}
                            </Box>
                          ))}
                          <Typography variant="body2" color="text.secondary">
                            {option.structured_formatting.secondary_text}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
            </div>
            <div className="w-full flex flex-col gap-1 ">
              <h6 className="text-tertiary publicSans text-base font-semibold ">
                Profile picture / Center logo
              </h6>
              <div className="w-full border border-dotted border-[#E0E2E7] bg-[#F9F9FC] rounded-lg flex items-center justify-center py-6">
                <div className="flex flex-col gap-4 items-center">
                  <img
                    src={
                      preview ??
                      defaultValues?.profilePicture ??
                      '/assets/icons/picture.png'
                    }
                    alt="picture"
                    className="w-8 h-8 rounded-lg "
                  />

                  <p className="publicSans text-sm font-normal leading-5 text-gray-400">
                    Drag and drop image here, or click add image
                  </p>
                  <Button
                    className="bg-[#F0F7FC] p-2 text-secondary publicSans font-semibold leading-5 "
                    component="label"
                    variant="contained"
                    color="secondary"
                  >
                    Add Image
                    <VisuallyHiddenInput
                      type="file"
                      required
                      accept="image/*"
                      onChange={handleImgChange}
                    />
                  </Button>
                </div>
              </div>
            </div>
            <div className="w-full grid grid-cols-2 gap-4">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Password
                </p>
                <TextFieldElement
                  name="password"
                  size="small"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Your password"
                  sx={styles.root}
                  validation={{
                    validate: (value) => {
                      if (value === '') return;

                      let regex = /^(?=.*[0-9])(?=.*[@#$%^&+=!])(.{8,})$/;
                      if (regex.test(value)) {
                        return true;
                      } else {
                        return 'Password must contain atleast 8 characters and a special character and a number (0-9) ';
                      }
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff fontSize="small" />
                          ) : (
                            <Visibility fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Re-type Password
                </p>
                <TextFieldElement
                  name="confirmPassword"
                  size="small"
                  fullWidth
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder="Your password"
                  sx={styles.root}
                  validation={{
                    validate: (
                      value,
                      values: {
                        password: string;
                        confirmPassword: string;
                      }
                    ) => {
                      if (values.password === '') return;

                      if (value === values?.password) {
                        console.log('Password Matched');

                        return true;
                      } else {
                        return 'Password does not match';
                      }
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          onMouseDown={handleMouseDownConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff fontSize="small" />
                          ) : (
                            <Visibility fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}

                  // onBlur={confirmPasswordBlurHandler}
                  // helperText={
                  //   errors.confirmPassword && "Password does not match"
                  // }
                />
              </div>
            </div>
            <div className="w-full flex justify-end pt-4">
              <button
                type="submit"
                className="publicSans text-sm font-semibold leading-5 bg-secondary rounded-lg p-3 text-white"
              >
                Save Changes
              </button>
            </div>
          </div>
        </FormContainer>
      )}
      <CropperModal
        modalOpen={modalOpen}
        src={src}
        setImage={setImage}
        setPreview={setPreview}
        setModalOpen={setModalOpen}
      />
    </>
  );
};

export default AdoptionDetails;
