import { Box, Button, MenuItem, Select } from "@mui/material";
import {
  FormContainer,
  TextFieldElement
} from "react-hook-form-mui";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { callAxios } from "../../../../utils/axios";
import { toastMessage } from "../../../../utils/toast";
import { CropperModal } from "../../../Common/ImageCropper";
import { SelectAllRounded } from "@mui/icons-material";

type FormProps = {
  vehicleNumber: string;
  description: string;
  images: string[];
  selectedDriver:''
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "white",
  borderRadius: "12px",
  border: "1px solid #fff",
  p: 2,
  // boxShadow: 24,
  // p: 4,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const styles = {
  root: {
    "& .MuiInputBase-input:-webkit-autofill-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input:-moz-autofill-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::-ms-input-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    backgroundColor: "#F9F9FC",
    borderRadius: "8px",
    color: "#858D9D !important",
  },
};

const CreateVan = ({ setOpen }: any) => {
  const defaultValues: FormProps = {
    vehicleNumber: "",
    description: "",
    images: [""],
    selectedDriver:""
  };

  const [vehicleNumber, setvehicleNumber] = useState("");
  const [preview, setPreview] = useState(null);
  const [src, setSrc] = useState(null);
  const [image, setImage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState({});

  const fetchDrivers = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `dashboard/driver/getAllDrivers?page=1&limit=0&activeDrivers=true`
      );
      setDrivers(data.data);
      setLoading(false);
    } catch (error) {
      toastMessage("error", "Failed to fetch drivers");
    }
  };

  useEffect(() => {
    fetchDrivers();
  }, []);

  const handleImgChange = (e) => {
    setSrc(URL.createObjectURL(e.target.files[0]));
    setModalOpen(true);
  };
  const handleDriverChange = (e) => {
    console.log(e.target.value)
    setSelectedDriver(e.target.value);
  };
  const onSubmit = async (values: FormProps) => {
    console.log(values);

    if (!image) {
      return toastMessage("Please upload Vehicle Image", "error");
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", image);
      const { data: result } = await callAxios(
        "post",
        "app/user/uploadImage",
        formData
      );

      const payload = {
        ...values,
        images: [result.url],
        selectedDriver :selectedDriver
      };

      const { data } = await callAxios("post", "dashboard/van/addVan", payload);
      console.log(data);
      setLoading(false);
      setOpen(false);
      // setProducts((prev) => [data.data, ...prev]);
      toastMessage("Van Created Successfully", "success");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  return (
    <Box sx={style}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
          <div className="w-full bg-white flex flex-col gap-6 ">
            <div className="w-full justify-between flex items-center">
              <h6 className="publicSans text-xl leading-8 font-semibold text-blackTheme">
                Create Van
              </h6>
              <img
                src="/assets/icons/cross.svg"
                alt=""
                className="cursor-pointer"
                onClick={() => setOpen(false)}
              />
            </div>
            <div className="w-full flex flex-row gap-1"></div>

            {/* Vehicle Number */}
            <div className="w-full flex flex-col gap-1">
              <p className="text-[#777980] publicSans text-sm font-medium leading-4">
                Vehicle Number:
              </p>
              <TextFieldElement
                name="vehicleNumber"
                placeholder="Enter Vehicle Number"
                size="small"
                value={vehicleNumber}
                onChange={(e) => setvehicleNumber(e.target.value)}
                required
                fullWidth
                sx={styles.root}
              />
            </div>
            <div className="w-full flex flex-col gap-1 ">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Vehicle Desscription
                </p>
                <TextFieldElement
                  name="description"
                  fullWidth
                  size="small"
                  placeholder="Enter Vehicle description"
                  sx={styles.root}
                  required
                />
              </div>
            </div>
            <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Select Driver
                </p>
              <Select
                name="selectedDriver"
                label="Select Driver"
                sx={styles.root}
                value={selectedDriver}
                onChange={handleDriverChange}
              >
                {drivers.map((driver) => (
                  <MenuItem
                    key={driver._id}
                    value={driver}
                    className="cursor-pointer"
                  >
                    {`${driver.username} (${driver.vehicleNumber})`}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="w-full flex flex-col gap-1 ">
              <h6 className="text-tertiary publicSans text-base font-semibold ">
                Vehicle Picture
              </h6>
              <div className="w-full border border-dotted border-[#E0E2E7] bg-[#F9F9FC] rounded-lg flex items-center justify-center py-6">
                <div className="flex flex-col gap-4 items-center">
                  <div className="rounded-lg bg-[#8CC8E4] p-3 ">
                    <img
                      // src="/assets/icons/picture.png"
                      src={preview ?? "/assets/icons/picture.png"}
                      alt="icon"
                      className="w-4 h-4"
                    />
                  </div>
                  <p className="publicSans text-sm font-normal leading-5 text-gray-400">
                    Drag and drop image here, or click add image
                  </p>
                  <Button
                    className="bg-[#F0F7FC] p-2 text-secondary publicSans font-semibold leading-5 "
                    component="label"
                    variant="contained"
                    color="secondary"
                  >
                    Add Image
                    <VisuallyHiddenInput
                      type="file"
                      required
                      accept="image/*"
                      onChange={handleImgChange}
                    />
                  </Button>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-end pt-4">
              <button className="publicSans text-sm font-semibold leading-5 bg-[#EF8214] rounded-lg p-3 text-white">
                Save
              </button>
            </div>
          </div>
        </FormContainer>
      </LocalizationProvider>
      <CropperModal
        modalOpen={modalOpen}
        src={src}
        setImage={setImage}
        setPreview={setPreview}
        setModalOpen={setModalOpen}
      />
    </Box>
  );
};

export default CreateVan;
