import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Loading from "react-fullscreen-loading";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import moment from "moment";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "white",
  borderRadius: "12px",
  border: "1px solid #fff",
  p: 2,
  // boxShadow: 24,
  // p: 4,
};

const EventDetails = ({
  setOpen,
  eventData,
  setRescheduleOpen,
  setPetDetailsOpen,
}: any) => {
  return (
    <Box sx={style}>
      <div className="w-full bg-white flex flex-col gap-6 ">
        <div className="w-full flex justify-end items-center gap-4">
          <img
            src="/assets/icons/pencil.png"
            alt="pencil"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setRescheduleOpen(true);
            }}
          />
          <img
            src="/assets/icons/delete.png"
            alt="pencil"
            className="cursor-pointer"
          />
          {/* <img
            src="/assets/icons/three-dots.svg"
            alt="pencil"
            className="cursor-pointer"
          /> */}
          <img
            src="/assets/icons/cross.svg"
            alt="pencil"
            className="cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>
        <div className="w-full flex  flex-col gap-2">
          <div className="w-full items-center grid grid-cols-12 ">
            <div className="bg-[#883DCF] p-2 w-fit h-fit rounded"></div>
            <div className="w-full col-span-11">
              <h6 className="text-blackTheme publicSans text-xl font-medium leading-8">
                {eventData?.services[0]?.name}
              </h6>
            </div>
          </div>
          <div className="w-full items-center grid grid-cols-12">
            <div className=""></div>
            <p className="text-[#667085] col-span-11 publicSans text-sm font-normal leading-5 ">
              {moment(eventData?.appointmentTimestamp).format("DD MMMM YYYY")}{" "}
              {eventData?.startTime} - {eventData?.endTime}
            </p>
          </div>
        </div>
        <div className="w-full flex  flex-col gap-2">
          <div className="w-full items-center grid grid-cols-12 ">
            <img src="/assets/icons/info.png" alt="" className="" />
            <div className="w-full col-span-11">
              <h6 className="text-blackTheme publicSans text-base font-medium leading-8">
                Contact Information
              </h6>
            </div>
          </div>
          <div className="w-full items-center grid grid-cols-12">
            <div className=""></div>
            <div className="w-full col-span-11 flex flex-col gap-1">
              <p className="text-[#667085] col-span-11 publicSans text-sm  font-medium leading-5 ">
                {eventData?.user?.fullname}
              </p>
              <p className="text-[#667085] col-span-11 publicSans text-sm  font-medium leading-5 ">
                {eventData?.user?.countryCode} {eventData?.user?.phone}
              </p>
              <p className="text-[#667085] col-span-11 publicSans text-sm  font-medium leading-5 ">
                {eventData?.pet?.breed}
              </p>
            </div>
          </div>
        </div>
        <button onClick={()=>{
          setOpen(false)
          setPetDetailsOpen(true)
        }} className="py-2 w-fit px-3 bg-[#883DCF] rounded-lg text-white publicSans text-sm font-semibold leading-5 ">
          Pet Passport
        </button>
      </div>
    </Box>
  );
};

export default EventDetails;
