import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import InputBase from "@mui/material/InputBase";
import Loading from "react-fullscreen-loading";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { callAxios } from "../../../utils/axios";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { toastMessage } from "../../../utils/toast";
import { useParams } from "react-router";

const Input = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: "8px",
    position: "relative",
    backgroundColor: "#F9F9FC",
    fontFamily: "Public Sans",
    border: "1px solid #E0E2E7",
    paddingLeft: "10px",
    fontSize: "14px",
  },
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "white",
  borderRadius: "12px",
  border: "1px solid #fff",
  p: 2,
  // boxShadow: 24,
  // p: 4,
};

const AvailablePets = () => {
  const [loading, setLoading] = useState(false);
  const [selectedPet, setSelectedPet] = useState("");
  const [pets, setPets] = useState([]);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [userOptions, setUserOptions] = useState([] as any);
  const [userLoading, setUserLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [checked, setChecked] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [status, setStatus] = useState("");

  const { adoptionId } = useParams();

  // const adoptionId = "655b05e70e2ccdb42c2a3cd3";

  const getAvailablePets = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `app/pet/getAvailablePet?page=1&limit=10&adoptionId=${adoptionId}`
        // `dashboard/pet/getPet?page=1&limit=10&adoptionId=${adoptionId}`
      );
      console.log(data);
      setPets(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  const getAllUsers = async (search: string) => {
    try {
      setUserLoading(true);
      const { data } = await callAxios(
        "get",
        `dashboard/dashUser/getAllAppUsers?page=1&limit=5&search=${search}`
      );
      console.log(data);
      setUserOptions(
        data?.data?.map((user: any) => ({
          label: user?.fullname + "-" + user?.username,
          value: user?._id,
        }))
      );
      setUserLoading(false);
    } catch (error) {
      console.log(error);
      setUserLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  const handleInputChange = (event, value) => {
    getAllUsers(value);
  };

  console.log(selectedUser);

  useEffect(() => {
    getAllUsers("");
  }, []);

  useEffect(() => {
    if (adoptionId) {
      getAvailablePets();
    }
  }, [adoptionId, checked]);

  const transferOwnership = async () => {
    if (!selectedUser) {
      return toastMessage("Please select a user.", "error");
    }

    try {
      setLoading(true);
      const { data } = await callAxios(
        "put",
        `app/pet/transferOwnership?requestedUser=${selectedUser?.value}&petId=${selected?._id}`
      );
      console.log(data);
      setLoading(false);
      setOpenTransferModal(false);
      setChecked(!checked);
      toastMessage(data?.message, "success");
      setSelectedPet("");
    } catch (error) {
      console.log(error);
      setUserLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  const handleStatusChange = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "put",
        `dashboard/pet/updatePet?id=${selected?._id}`,
        {
          status: status,
        }
      );
      console.log(data);
      setLoading(false);
      setOpenStatus(false);
      setChecked(!checked);
      toastMessage(data?.message, "success");
      setSelectedPet("");
      setStatus("");
    } catch (error) {
      console.log(error);
      setUserLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex justify-between items-center">
          <div className="w-full flex flex-col gap-2">
            <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
              Available Pets
            </h6>
            <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
              Available Pets
            </p>
          </div>
          <div className="flex items-center gap-1 cursor-pointer">
            <p className="text-[#858D9D] publicSans text-xs font-medium leading-4 ">
              Sort:
            </p>
            <img src="/assets/icons/sort.svg" alt="sort" className="" />
          </div>
        </div>
        <div className="w-full grid grid-cols-5 gap-4">
          {pets?.map((pet) => {
            return (
              <div className="w-full relative flex flex-col space-y-4 items-center bg-white rounded-lg shadow py-4 px-4">
                <img
                  src="/assets/icons/three-dots.svg"
                  alt="three dots"
                  className="cursor-pointer absolute top-2 right-2"
                  onClick={() => {
                    if (selectedPet === pet?._id) {
                      setSelectedPet("");
                    } else {
                      setSelectedPet(pet?._id);
                    }
                  }}
                />

                {selectedPet === pet?._id && (
                  <div
                    className="w-full bg-white absolute transform translate-y-1/3 px-2 py-4 flex flex-col gap-2"
                    onBlur={() => setSelectedPet("")}
                  >
                    <div
                      onClick={() => {
                        setOpenTransferModal(true);

                        setSelected(pet);
                      }}
                      className="py-2 cursor-pointer px-3 flex justify-center bg-secondary text-white rounded-lg publicSans text-sm font-semibold leading-5 "
                    >
                      Transfer Ownership
                    </div>
                    <div className="py-2 px-3 cursor-pointer flex justify-center bg-[#F9B413] text-white rounded-lg publicSans text-sm font-semibold leading-5 ">
                      View Profile
                    </div>
                    <div
                      onClick={() => {
                        setOpenStatus(true);
                        setSelected(pet);
                        setStatus(pet?.status);
                      }}
                      className="py-2 px-3 cursor-pointer flex justify-center bg-[#8CC8E4] text-white rounded-lg publicSans text-sm font-semibold leading-5 "
                    >
                      Change Status
                    </div>
                  </div>
                )}

                {pet?.profilePicture.length > 0 ? (
                  <img
                    src={pet?.profilePicture[0]?.url}
                    alt="pet"
                    className="rounded-full w-20 h-20"
                  />
                ) : (
                  <img
                    src="/assets/images/pet.jpeg"
                    alt="pet"
                    className="rounded-full w-20 h-20"
                  />
                )}

                <div className="w-full flex flex-col gap-2 items-center">
                  <p className="publicSans text-lg font-medium leading-5 text-blackTheme text-center rounded-md">
                    {pet?.name}
                  </p>
                  <div className="flex items-center justify-center bg-[#F9B41310] py-1 px-2 text-[#F9B413] publicSans text-sm leading-5  ">
                    Age: {moment().diff(pet?.dob, "years")} years
                  </div>
                </div>
                <img
                  src="/assets/icons/divider.svg"
                  alt=""
                  className="w-full"
                />
                <div className="w-full flex justify-between items-center ">
                  <p className="publicSans text-sm leading-5 text-[#667085]">
                    Owner
                  </p>
                  <p className="text-[#667085] text-sm font-medium leading-5 whitespace-nowrap">
                    {pet?.user?.fullname}
                  </p>
                </div>
                <div className="w-full flex justify-between items-center ">
                  <p className="publicSans text-sm leading-5 text-[#667085]">
                    Status
                  </p>
                  <p
                    className={`text-sm font-medium leading-5 whitespace-nowrap ${
                      pet?.status === "AVAILABLE"
                        ? "text-[#11C661]"
                        : pet?.status === "REQUESTED"
                        ? "text-[#F9C80E]"
                        : pet?.status === "UNAVAILABLE"
                        ? "text-[#E00D1C]"
                        : pet?.status === "ADOPTED"
                        ? "text-[#858D9D]"
                        : ""
                    } `}
                  >
                    {pet?.status}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Modal
        open={openTransferModal}
        onClose={() => setOpenTransferModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="w-full bg-white flex flex-col gap-6 relative p-2 ">
            <div className="absolute rounded-full bg-[#F2F2F2] p-3 w-fit right-0 -top-2 ">
              <img
                src="/assets/icons/cross.svg"
                alt="close"
                className="cursor-pointer"
                onClick={() => setOpenTransferModal(false)}
              />
            </div>
            <h5 className="publicSans text-xl font-semibold leading-7 text-center  ">
              Transfer Ownership
            </h5>
            <div className="w-full flex flex-col gap-4">
              <div className="flex gap-3 items-center">
                <img
                  src={selected?.profilePicture[0]?.url}
                  alt={selected?.name}
                  className="rounded-full h-12 w-12"
                />
                <div className="flex flex-col items-start gap-1">
                  <p className="publicSans text-base font-semibold leading-5 text-blackTheme">
                    {selected?.name}
                  </p>
                  <p className="publicSans text-sm font-semibold leading-5 text-gray-500">
                    {selected?.breed}
                  </p>
                  <p className="publicSans text-xs leading-5 text-[#8CC8E4]">
                    {moment().diff(selected?.dob, "years")} years
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col gap-2  ">
              <p className="publicSans text-sm text-gray-500 leading-5">
                Owner
              </p>
              <div className="flex gap-3 items-center ">
                <img
                  src={selected?.user?.profilePicture}
                  alt={selected?.user?.fullname}
                  className="rounded-full h-12 w-12"
                />
                  <div className="flex flex-col items-start gap-1">
                  <p className="publicSans text-base font-semibold leading-5 text-blackTheme">
                    {selected?.user?.fullname}
                  </p>
                  <p className="publicSans text-sm font-semibold leading-5 text-gray-500">
                    {selected?.user?.username}
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div className="w-full flex flex-col gap-4">
              <h6 className="text-blackTheme publicSans font-semibold leading-7">
                Choose owner for transfer
              </h6>
              <Autocomplete
                options={userOptions}
                fullWidth
                sx={{
                  backgroundColor: "#E0E2E7",
                }}
                getOptionLabel={(option: any) => option.label}
                onInputChange={handleInputChange}
                onChange={(event, value) => {
                  setSelectedUser(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    placeholder="Type to Search"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {userLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div className="w-full flex justify-end gap-4">
              <button
                onClick={() => setOpenTransferModal(false)}
                className="px-3 py-2 border border-[#858D9D] text-[#858D9D] rounded-lg publicSans text-sm font-semibold leading-5 "
              >
                Cancel
              </button>
              <button
                onClick={transferOwnership}
                className="px-3 py-2 border border-[#1F9CB9] bg-[#1F9CB9] text-white  rounded-lg publicSans text-sm font-semibold leading-5 "
              >
                Transfer Now
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openStatus}
        onClose={() => setOpenStatus(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <div className="w-full bg-white flex flex-col gap-4 relative p-2 ">
            <h5 className="publicSans text-xl font-semibold leading-7 text-center  ">
              Change Status
            </h5>

            <Select
              fullWidth
              value={status}
              size="small"
              defaultValue={selected?.status}
              onChange={(e) => setStatus(e.target.value)}
              placeholder="Select Status"
              input={<Input />}
            >
              <MenuItem value="AVAILABLE">Available</MenuItem>
              <MenuItem value="REQUESTED">Requested</MenuItem>
              <MenuItem value="UNAVAILABLE">Unavailable</MenuItem>
              <MenuItem value="ADOPTED">Adopted</MenuItem>
            </Select>
            <div className="w-full flex justify-end gap-4">
              <button
                onClick={() => setOpenStatus(false)}
                className="px-3 py-2 border border-[#858D9D] text-[#858D9D] rounded-lg publicSans text-sm font-semibold leading-5 "
              >
                Cancel
              </button>
              <button
                onClick={handleStatusChange}
                className="px-3 py-2 border border-[#1F9CB9] bg-[#1F9CB9] text-white  rounded-lg publicSans text-sm font-semibold leading-5 "
              >
                Save
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AvailablePets;
