import './Calendar.css';

import { useEffect, useRef, useState } from 'react';

import EventDetails from './EventDetails';
import FullCalendar from '@fullcalendar/react';
import Loading from 'react-fullscreen-loading';
import Modal from '@mui/material/Modal';
import PetDetails from '../Common/PetDetails';
import Resechdule from '../Common/Resechdule';
import { callAxios } from '../../../utils/axios';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import timeGridPlugin from '@fullcalendar/timegrid';
import { toastMessage } from '../../../utils/toast';

const Calendar = () => {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const calendarRef = useRef(null);
  const [calendarView] = useState('dayGridMonth');
  const [events, setEvents] = useState([]);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [rescheduleOpen, setRescheduleOpen] = useState(false);
  const [petDetailsOpen, setPetDetailsOpen] = useState(false);
  const [eventData, setEventData] = useState({});

  useEffect(() => {
    const { current: calendarDom } = calendarRef;
    const API = calendarDom ? calendarDom?.getApi() : null;
    API && API.changeView(calendarView);
  }, [calendarView]);

  const getMiddleDate = (start, end) => {
    let startDate = moment(start);
    let endDate = moment(end);
    let diff = endDate.diff(startDate); // Corrected the order of dates
    let middleDate = moment(startDate)
      .add(diff / 2, 'milliseconds')
      .format('YYYY-MM-DD');

    return middleDate;
  };

  function concatDateAndTime(date, time) {
    console.log(date, time, '---');

    // Format the date to a YYYY-MM-DD string
    let formattedDate = moment(date).format('YYYY-MM-DD');

    // Concatenate the date string with the time string
    let dateTimeString = `${formattedDate} ${time}`;

    // Parse the concatenated string as a new Moment date-time object
    return moment(dateTimeString, 'YYYY-MM-DD HH:mm');
  }

  const getEvents = async (dateInfo) => {
    console.log(dateInfo);

    const date = moment(getMiddleDate(dateInfo.startStr, dateInfo.endStr));
    console.log(date);

    let firstDateOfMonth = date.clone().startOf('month').format('YYYY-MM-DD');
    let lastDateOfMonth = date.clone().endOf('month').format('YYYY-MM-DD');

    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/services/getBookings?from=${firstDateOfMonth}&to=${lastDateOfMonth}`
      );

      console.log(data);

      let evData = data?.data?.map((item: any) => {
        return {
          title: item?.services[0]?.name,
          start: concatDateAndTime(
            item?.appointmentTimesamp,
            item?.startTime
          ).format('YYYY-MM-DD HH:mm'),
          end: concatDateAndTime(
            item?.appointmentTimesamp,
            item?.endTime
          ).format('YYYY-MM-DD HH:mm'),
          backgroundColor: '#F4ECFB',
          id: item?._id,
        };
      });
      setEvents(evData);
      console.log(evData);

      // debugger;

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastMessage(error?.response?.data?.message || error?.message, 'error');
    }
  };

  const getEvent = async (eventInfo) => {
    try {
      const { data } = await callAxios(
        'get',
        `dashboard/services/getAppointmentById?id=${eventInfo.event.id}`
      );
      console.log(data);
      setEventData(data?.data);
      setDetailsOpen(true);
    } catch (error) {
      setLoading(false);
      toastMessage(error?.response?.data?.message || error?.message, 'error');
    }
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex justify-between items-center">
          <div className="w-full flex flex-col gap-2">
            <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
              Calendar
            </h6>
            <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
              Month View
            </p>
          </div>
        </div>
        <div className="w-full bg-white rounded-lg shadow p-4 flex flex-col gap-4">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView={calendarView}
            handleWindowResize={true}
            ref={calendarRef}
            eventTimeFormat={{
              hour: 'numeric',
              minute: '2-digit',
              meridiem: 'short',
            }}
            aspectRatio={2}
            headerToolbar={{
              end: 'toggleDay toggleWeek toggleMonth',
              start: 'prev today next',
              center: 'title',
            }}
            customButtons={{
              toggleDay: {
                text: 'Day',
                click: () => {
                  calendarRef.current.getApi().changeView('timeGridDay');
                },
              },
              toggleWeek: {
                text: 'Week',
                click: () => {
                  calendarRef.current.getApi().changeView('dayGridWeek');
                },
              },
              toggleMonth: {
                text: 'Month',
                click: () => {
                  calendarRef.current.getApi().changeView('dayGridMonth');
                },
              },
            }}
            events={events}
            datesSet={getEvents}
            eventClick={(info) => {
              getEvent(info);
            }}
          />
        </div>
      </div>
      <Modal
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EventDetails
          setOpen={setDetailsOpen}
          eventData={eventData}
          setRescheduleOpen={setRescheduleOpen}
          setPetDetailsOpen={setPetDetailsOpen}
        />
      </Modal>
      <Modal
        open={rescheduleOpen}
        onClose={() => setRescheduleOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Resechdule
          setOpen={(value) => {
            setRescheduleOpen(value);
            if (!value) {
              const { current: calendarDom } = calendarRef;
              const API = calendarDom ? calendarDom?.getApi() : null;
              API && API.changeView(calendarView);
            }
          }}
          eventData={eventData}
          serviceId={
            (eventData as any).services
              ? (eventData as any).services[0]._id
              : ''
          }
          bookingId={(eventData as any)._id}
        />
      </Modal>
      <Modal
        open={petDetailsOpen}
        onClose={() => setPetDetailsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PetDetails
          setOpen={setPetDetailsOpen}
          existingData={eventData}
          checked={checked}
          setChecked={setChecked}
        />
      </Modal>
    </>
  );
};

export default Calendar;
