import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const Revenue = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        width={180}
        height={100}
        data={data.monthlyData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="0.4 1" vertical={false} />

        <XAxis dataKey="Month" axisLine={false} />

        <YAxis axisLine={false} tickLine={false} />
        <Tooltip />
        <Legend />
        {/* <Line
        type="monotone"
        dataKey="Users"
        stroke="#8884d8"
        activeDot={{ r: 8 }}
      /> */}
        <Line
          type="monotone"
          dataKey="ProductsRevenue"
          stroke="#82ca9d"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="BookingRevenue"
          stroke="#ff7300"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Revenue;
