import { Box } from '@mui/material';
import {
    FormContainer,
    TimePickerElement,
} from 'react-hook-form-mui';
import { useState } from 'react';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Loading from 'react-fullscreen-loading';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { callAxios } from '../../../../utils/axios';
import { toastMessage } from '../../../../utils/toast';
import './mobileGrooming.css'
import moment from 'moment';
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'white',
    borderRadius: '12px',
    border: '1px solid #fff',
    p: 2,
    // boxShadow: 24,
    // p: 4,
};

const styles = {
    root: {
        '& .MuiInputBase-input:-webkit-autofill-placeholder': {
            color: '#858D9D',
            backgroundColor: '#fff',
            fontSize: '14px',
            fontFamily: 'Public Sans',
            fontWeight: 400,
        },
        '& .MuiInputBase-input:-moz-autofill-placeholder': {
            color: '#858D9D',
            backgroundColor: '#fff',
            fontSize: '14px',
            fontFamily: 'Public Sans',
            fontWeight: 400,
        },
        '& .MuiInputBase-input::-ms-input-placeholder': {
            color: '#858D9D',
            backgroundColor: '#fff',
            fontSize: '14px',
            fontFamily: 'Public Sans',
            fontWeight: 400,
        },
        '& .MuiInputBase-input::placeholder': {
            color: '#858D9D',
            backgroundColor: '#fff',
            fontSize: '14px',
            fontFamily: 'Public Sans',
            fontWeight: 400,
        },
        width: '100%',
        backgroundColor: '#F9F9FC',
        borderRadius: '8px',
        color: '#858D9D !important',
    },
};

type FormProps = {
    firstTime: string;
    secondTime: string;
    thirdTime: string;
};

const AssignNewTime = ({ setOpen, booking }: any) => {
    const [loading, setLoading] = useState(false);

    const onSubmit = async (values: FormProps) => {

        let payload = [moment(new Date(values.firstTime)).format('HH:mm'), moment(new Date(values.secondTime)).format('HH:mm'), moment(new Date(values.thirdTime)).format('HH:mm')]
        try {
            setLoading(true);

            await callAxios('put', `dashboard/grooming/pet-grooming/${booking._id}`, {
                suggestedTime: payload,
                status: "waiting"
            })

            toastMessage('success', 'Request Updated successfully');

        } catch (error) {
            console.log(error);
            toastMessage(
                error?.response?.data?.message || 'Something went wrong',
                'error'
            );
        }
        finally {
            setOpen(false);
            setLoading(false);
        }
    };
    return (
        <>
            <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
            <Box sx={style}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <>
                        <div className="w-full bg-white flex flex-col gap-6 ">
                            <div className="w-full justify-between flex items-center">
                                <h6 className="publicSans text-xl leading-8 font-semibold text-blackTheme">
                                    Give New Time
                                </h6>
                                <img
                                    src="/assets/icons/cross.svg"
                                    alt=""
                                    className="cursor-pointer"
                                    onClick={() => setOpen(false)}
                                />
                            </div>
                            <div className="w-full gap-1 text-left">
                                <FormContainer onSuccess={onSubmit}>
                                    <div className="space-y-4">
                                        <TimePickerElement
                                            name="firstTime"
                                            required
                                            inputProps={{
                                                sx: styles.root,
                                                size: 'small',
                                            }}
                                        />
                                        <TimePickerElement
                                            name="secondTime"
                                            required
                                            inputProps={{
                                                sx: styles.root,
                                                size: 'small',
                                            }}
                                        />
                                        <TimePickerElement
                                            name="thirdTime"
                                            required
                                            inputProps={{
                                                sx: styles.root,
                                                size: 'small',
                                            }}
                                        />
                                        <div className="w-full justify-between items-center flex">
                                            <button
                                                className="px-3 py-2 rounded-lg border border-[#858D9D] text-[#858D9D] publicSans text-sm font-semibold leading-5 cursor-pointer "
                                                onClick={() => {
                                                    setOpen(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            {/* Save Button */}
                                            <button
                                                type='submit'
                                                className="px-3 py-2 rounded-lg border border-secondary text-white bg-secondary publicSans text-sm font-semibold leading-5 cursor-pointer "
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </FormContainer>
                            </div>

                        </div>
                    </>
                </LocalizationProvider>
            </Box>
        </>
    );
};

export default AssignNewTime;