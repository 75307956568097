import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
import dayjs from "dayjs";

import {
  FormContainer,
  TextFieldElement,
  AutocompleteElement,
  SelectElement,
  DatePickerElement,
} from "react-hook-form-mui";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { IconButton, InputAdornment } from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import Button from "@mui/material/Button";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type FormProps = {
  name: string;
  breed: string;
  microchipNumber: string;
  dob: string;
  weight: number;
  gender: string;
  petCategory: string;
};

const styles = {
  root: {
    "& .MuiInputBase-input:-webkit-autofill-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input:-moz-autofill-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::-ms-input-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    backgroundColor: "#F9F9FC",
    borderRadius: "8px",
    color: "#858D9D !important",
  },
};

const AddPet = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);

  // const handleImageChange = (e: any) => {
  //   setImages(e.target.files);
  //   let images = [];
  //   for (const image of e.target.files) {
  //     previewImages.push(URL.createObjectURL(image));
  //   }
  // };
 

const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const files = Array.from(e.target.files || []);

  if (files.length > 0) {
    // Add new images to the existing list
    setImages(prevImages => [...prevImages, ...files]);
    
    // Create new preview URLs for newly selected images
    const newPreviewImages = files.map(file => URL.createObjectURL(file));
    setPreviewImages(prevPreviews => [...prevPreviews, ...newPreviewImages]);
  }
};


  const defaultValues = {
    name: "",
    breed: "",
    microchipNumber: "",
    dob: "",
    weight: undefined,
    gender: "",
    petCategory: "",
  };

  const getPetCategories = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", "app/pet/getPetCategory");
      console.log(data);
      setCategoryOptions(
        data.data.map((item: any) => ({ id: item._id, label: item.name }))
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getPetCategories();
  }, []);

  const onSubmit = async (values: FormProps) => {
    console.log(values);
    if (images.length === 0) {
      toastMessage("Please add atleast one image", "error");
      return;
    }
    try {
      setLoading(true);
      let imagesUrls = [];
      for (const image of images) {
        const formData = new FormData();
        formData.append("file", image);
        const { data } = await callAxios(
          "post",
          "app/user/uploadImage",
          formData
        );
        imagesUrls.push({
          url: data?.url,
          isBestImage: false,
          active: true,
        });
      }

      imagesUrls[0].isBestImage = true;
      const { data } = await callAxios("post", "dashboard/pet/addPet", {
        ...values,
        profilePicture: imagesUrls,
      });
      console.log(data);
      setLoading(false);
      toastMessage("Pet Added Successfully", "success");
      navigate("/adoption/available-pets");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };
  // only numbers are accepted
  const handleMicrochipChange = (event) => {
    const value = event.target.value;
    // Allow only numbers
    if (/^\d*$/.test(value)) {
      event.target.value = value;
    } else {
      event.target.value = value.slice(0, -1);
    }
  };
// only positive numbers are accepted
  const handleWeightChange = (event) => {
    const value = event.target.value;
    // Allow only positive numbers and zero
    if (/^\d*\.?\d*$/.test(value)) {
      event.target.value = value;
    } else {
      event.target.value = value.slice(0, -1);
    }
  };
  useEffect(() => {
    // Cleanup object URLs when component unmounts or images change
    return () => {
      previewImages.forEach(image => URL.revokeObjectURL(image));
    };
  }, [previewImages]);
  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex justify-between items-center">
          <div className="w-full flex flex-col gap-2">
            <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
              Add a New Pet
            </h6>
            <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
              Pet Profile Creation
            </p>
          </div>
        </div>
        <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
          <div className="w-full bg-white rounded-lg shadow p-4 flex flex-col gap-4">
            <h6 className="text-tertiary publicSans text-base font-semibold ">
              Add a New pet
            </h6>
            <div className="w-full grid grid-cols-2 gap-4">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Name
                </p>
                <TextFieldElement
                  name="name"
                  fullWidth
                  size="small"
                  placeholder="Ex-Tom"
                  sx={styles.root}
                  required
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Breed
                </p>
                <TextFieldElement
                  name="breed"
                  fullWidth
                  size="small"
                  placeholder="Ex-Labrador"
                  sx={styles.root}
                  required
                />
              </div>
            </div>
            <div className="w-full grid grid-cols-2 gap-4">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Microchip Number
                </p>
                <TextFieldElement
                  name="microchipNumber"
                  fullWidth
                  size="small"
                  placeholder="#"
                  sx={styles.root}
                  required
                  inputProps={{ onInput: handleMicrochipChange }}
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Date of Birth
                </p>
                <DatePickerElement
                  name="dob"
                  // placeholder="Ex-Labrador"
                  sx={styles.root}
                  inputProps={{
                    size: "small",
                    fullWidth: true,
                  }}
                  required
                  maxDate={dayjs()} // Disable future dates
                />
              </div>
            </div>
            <div className="w-full grid grid-cols-2 gap-4">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Weight
                </p>
                <TextFieldElement
                  name="weight"
                  fullWidth
                  type="number"
                  size="small"
                  placeholder="Kg"
                  sx={styles.root}
                  required
                  inputProps={{ onInput: handleWeightChange }}
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Gender
                </p>
                <SelectElement
                  name="gender"
                  fullWidth
                  size="small"
                  options={[
                    { id: "MALE", label: "Male" },
                    { id: "FEMALE", label: "Female" },
                  ]}
                  // placeholder="Ex-Labrador"
                  sx={styles.root}
                  required
                />
              </div>
            </div>
            <div className="w-full grid grid-cols-2 gap-4">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Pet Category
                </p>
                <SelectElement
                  name="petCategory"
                  fullWidth
                  size="small"
                  options={categoryOptions}
                  // placeholder="Ex-Labrador"
                  sx={styles.root}
                  required
                />
              </div>
            </div>
            <div className="w-full flex flex-col gap-1 ">
              <h6 className="text-tertiary publicSans text-base font-semibold ">
                Upload Photos
              </h6>
              <div className="w-full border border-dotted border-[rgb(224,226,231)] bg-[#F9F9FC] rounded-lg flex items-center justify-center py-6">
                <div className="flex flex-col gap-4 items-center">
                  {previewImages.length > 0 ? (
                    <div className="flex gap-2 items-center">
                      {previewImages.map((image) => {
                        return (
                          <img
                            src={image}
                            alt=""
                            className="w-12 h-12 rounded"
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <div className="rounded-lg bg-[#8CC8E4] p-3 ">
                      <img
                        src="/assets/icons/picture.png"
                        alt="icon"
                        className="w-4 h-4"
                      />
                    </div>
                  )}

                  <p className="publicSans text-sm font-normal leading-5 text-gray-400">
                    Drag and drop image here, or click add image
                  </p>
                  <Button
                    className="bg-[#F0F7FC] p-2 text-secondary publicSans font-semibold leading-5 "
                    component="label"
                    variant="contained"
                    color="secondary"
                  >
                    Add Image
                    <VisuallyHiddenInput
                      type="file"
                      required
                      accept="image/*"
                      multiple
                      onChange={handleImageChange}
                    />
                  </Button>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-end pt-4">
              <button
                type="submit"
                className="publicSans text-sm font-semibold leading-5 bg-secondary rounded-lg p-3 text-white"
              >
                Save Changes
              </button>
            </div>
          </div>
        </FormContainer>
      </div>
    </>
  );
};

export default AddPet;
