import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { callAxios } from "../../../../utils/axios";
import { toastMessage } from "../../../../utils/toast";
import Loading from "react-fullscreen-loading";
// Table Imports
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import EditService from "./EditService";
import DeleteService from "./DeleteService";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: "Public Sans",
  fontSize: "14px",
  lineHeight: "20px",
  borderBottom: "none",
  color: "#1D1F2C",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontFamily: "Public Sans",
  fontSize: "14px",
  fontWeight: 500, // Customize cell text styling
  lineHeight: "20px",
  borderBottom: "none",
  color: "#667085",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#fff", // Customize table head row background color
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const ServicesTable = ({ checked, setChecked }: any) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [existingData, setExistingData] = useState(null as any);
  const [services, setServices] = useState([] as any);

  const getAllServices = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `dashboard/services/getAllServices?page=${page}&limit=10&vet=${searchParams.get(
          "userId"
        )}`
      );
      console.log(data);

      setServices(data?.data);
      setCount(data?.totalData);
      setTotalPages(data?.totalPage);

      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  useEffect(() => {
    if (searchParams.get("userId")) {
      getAllServices();
    }
  }, [page, checked]);

  return (
    <>
      <div className="w-full bg-white rounded-lg shadow p-4 flex flex-col gap-4">
        <h6 className="text-tertiary publicSans text-base font-semibold ">
          Services
        </h6>

        <div className="w-full">
          <Table
            sx={{
              borderCollapse: "separate !important",
              width: "100%",
            }}
          >
            <TableHead>
              <CustomTableHeadRow>
                <CustomTableHeadCell>Service Name</CustomTableHeadCell>
                <CustomTableHeadCell>Pet Types</CustomTableHeadCell>
                <CustomTableHeadCell>Price</CustomTableHeadCell>
                <CustomTableHeadCell>Service Type</CustomTableHeadCell>
                <CustomTableHeadCell>Time</CustomTableHeadCell>
                <CustomTableHeadCell>Modify</CustomTableHeadCell>
              </CustomTableHeadRow>
            </TableHead>
            <TableBody>
              {services.map((service: any) => {
                return (
                  <CustomTableBodyRow key={service?._id}>
                    <CustomTableBodyCell>
                      <div className="flex items-center gap-2">
                        <img
                          src={
                            service?.images?.length > 0
                              ? service?.images[0]
                              : "/assets/images/user-logo.png"
                          }
                          alt=""
                          className="w-8 h-8 rounded-full"
                        />
                        <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                          {service?.name}
                        </p>
                      </div>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      {service?.petCategory?.name}
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>{service?.price}</CustomTableBodyCell>
                    <CustomTableBodyCell>
                      {service?.serviceTypeCheck}
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      {service?.time + " Minutes"}
                    </CustomTableBodyCell>

                    <CustomTableBodyCell>
                      <div className="flex gap-2">
                        <button
                          onClick={() => {
                            setExistingData(service);
                            setEditOpen(true);
                          }}
                          className="text-white publicSans text-sm font-medium leading-5 bg-secondary py-1 px-2 rounded "
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => {
                            setExistingData(service);
                            setDeleteOpen(true);
                          }}
                          className="text-white publicSans text-sm font-medium leading-5 bg-[#EF8214] py-1 px-2 rounded "
                        >
                          Delete
                        </button>
                      </div>
                    </CustomTableBodyCell>
                  </CustomTableBodyRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <div className="w-full flex justify-end items-center p-4">
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            color="secondary"
            onChange={(e, value) => setPage(value)}
          />
        </div>
      </div>
      <Modal
        open={editOpen}
        onClose={() => setEditOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <EditService
          open={editOpen}
          setOpen={setEditOpen}
          existingData={existingData}
          checked={checked}
          setChecked={setChecked}
        />
      </Modal>
      <Modal
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <DeleteService
          open={deleteOpen}
          setOpen={setDeleteOpen}
          existingData={existingData}
          checked={checked}
          setChecked={setChecked}
        />
      </Modal>
    </>
  );
};

export default ServicesTable;
