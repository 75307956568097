import React, { useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import axios from "axios";
import { backendUrl } from "../../utils/axios";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toastMessage } from "../../utils/toast";
import Loading from "react-fullscreen-loading";

type FormProps = {
  password: string;
  confirmPassword: string;
};
const styles = {
  root: {
    "& .MuiInputBase-input:-webkit-autofill-placeholder": {
      color: "#AEB4BE",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input:-moz-autofill-placeholder": {
      color: "#AEB4BE",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::-ms-input-placeholder": {
      color: "#AEB4BE",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#AEB4BE",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    backgroundColor: "#fff",
  },
};

const NewPassword = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [values, setValues] = useState<FormProps>();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(searchParams.get("token") || "");
  const [errors, setErrors] = useState({
    password: false,
    confirmPassword: false,
  });
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit = async (values: FormProps) => {
    console.log(values);

    try {
      setLoading(true);
      const { data } = await axios.post(
        `${backendUrl}/dashboard/auth/createNewPassword`,
        {
          password: values.password,
          token,
        }
      );
      console.log(data);

      setLoading(false);
      toastMessage(data?.message, "success");

      navigate(`/login`);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  const defaultValues: FormProps = { password: "", confirmPassword: "" };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full grid grid-cols-2 h-full p-8 bg-primary">
        <div className="flex w-full h-full relative">
          <div className="absolute rounded-xl bg-white p-6 bottom-4 right-4 ">
            <img src="/assets/images/logo.png" alt="logo" className="w-12 " />
          </div>
          <img
            src="/assets/images/login-bg.png"
            className="w-full h-full min-h-[90vh] rounded-lg"
            alt="login-bg"
          />
        </div>
        <div className="flex w-full items-center justify-center ">
          <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
            <div className="w-96 flex flex-col gap-8 items-center ">
              <div className="flex flex-col gap-3 items-center justify-center">
                <h5 className="publicSans text-2xl font-semibold leading-8 text-blackTheme">
                  Create New Password
                </h5>
                <p className="publicSans text-base font-normal leading-6 text-gray-500">
                  Your password must be different from your previous password
                </p>
              </div>
              <div className="flex flex-col gap-4 w-full">
                <div className="flex flex-col gap-1 w-full">
                  <p className="text-tertiary font-medium text-sm leading-5">
                    New Password
                  </p>
                  <TextFieldElement
                    name="password"
                    size="small"
                    fullWidth
                    sx={styles.root}
                    type={showPassword ? "text" : "password"}
                    required
                    validation={{
                      validate: (value) => {
                        console.log(value);
                        let regex = /^(?=.*[0-9])(?=.*[@#$%^&+=!])(.{8,})$/;
                        if (regex.test(value)) {
                          return true;
                        } else {
                          return "Password must contain atleast 8 characters and a special character and a number (0-9) ";
                        }
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="flex flex-col gap-1 w-full">
                  <p className="text-tertiary font-medium text-sm leading-5">
                    Confirm Password
                  </p>
                  <TextFieldElement
                    name="confirmPassword"
                    size="small"
                    fullWidth
                    type={showConfirmPassword ? "text" : "password"}
                    required
                    sx={styles.root}
                    validation={{
                      validate: (
                        value,
                        values: {
                          password: string;
                          confirmPassword: string;
                        }
                      ) => {
                        console.log(values);

                        if (value === values?.password) {
                          console.log("Password Matched");

                          return true;
                        } else {
                          return "Password does not match";
                        }
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                            onMouseDown={handleMouseDownConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={errors.confirmPassword}
                    // onBlur={confirmPasswordBlurHandler}
                    // helperText={
                    //   errors.confirmPassword && "Password does not match"
                    // }
                  />
                </div>
              </div>
              <button
                className="w-full rounded-lg bg-secondary py-2 flex items-center justify-center text-white leading-5 text-sm tracking-wide"
                type="submit"
              >
                Reset Password
              </button>
            </div>
          </FormContainer>
        </div>
      </div>
    </>
  );
};

export default NewPassword;
