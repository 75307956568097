import React, { useState } from "react";

import axios from "axios";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "../../utils/toast";
import Loading from "react-fullscreen-loading";

import { backendUrl } from "../../utils/axios";

type FormProps = {
  email: string;
};

const styles = {
  root: {
    "& .MuiInputBase-input:-webkit-autofill-placeholder": {
      color: "#AEB4BE",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input:-moz-autofill-placeholder": {
      color: "#AEB4BE",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::-ms-input-placeholder": {
      color: "#AEB4BE",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#AEB4BE",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    backgroundColor: "#fff",
  },
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: FormProps) => {
    console.log(values);

    try {
      setLoading(true);
      const { data } = await axios.post(`${backendUrl}/dashboard/auth/sendOtp`, values);
      console.log(data);
      setLoading(false);
      toastMessage(data.message, "success");

      navigate(`/verify-otp?token=${data.token}`);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  const defaultValues: FormProps = { email: "" };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full grid grid-cols-2 h-full p-8 bg-primary">
        <div className="flex w-full h-full relative">
          <div className="absolute rounded-xl bg-white p-6 bottom-4 right-4 ">
            <img src="/assets/images/logo.png" alt="logo" className="w-12 " />
          </div>
          <img
            src="/assets/images/login-bg.png"
            className="w-full h-full min-h-[90vh] rounded-lg"
            alt="login-bg"
          />
        </div>
        <div className="flex w-full items-center justify-center ">
          <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
            <div className="w-96 flex flex-col gap-8 ">
              <div className="flex flex-col gap-3">
                <h2 className="publicSans text-2xl font-semibold leading-8 text-blackTheme ">
                  Forgot Password
                </h2>
                <p className="publicSans text-base font-normal leading-6 text-gray-500 ">
                  Enter your email address and we’ll send you an OTP on mail to
                  reset your password
                </p>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-3">
                  <p className="text-tertiary font-medium text-sm leading-5">
                    Email
                  </p>
                  <TextFieldElement
                    name="email"
                    type="email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                    sx={styles.root}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <button
                  className="w-full rounded-lg bg-secondary py-2 flex items-center justify-center text-white leading-5 text-sm tracking-wide"
                  type="submit"
                >
                  Send Otp
                </button>
              </div>
            </div>
          </FormContainer>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
