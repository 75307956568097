import React, { useEffect, useState } from "react";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import moment from "moment";

type Props = {};

const ListofNotifications = ({ allNotifications, setAllNotifications }) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(1);

  const getAllNotification = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `dashboard/notifications/getNotifications`
      );
      console.log(data);
      setAllNotifications(data.data);

      //   setTotalPages(data.totalPages);
      //   setCount(data.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastMessage(error.message, "error");
    }
  };
  const cancelNotification = async (id) => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "put",
        `dashboard/notifications/updateNotification?id=${id}`,
        { cancel: true }
      );
      console.log(data);
      toastMessage("Notification Cancelled Successfully", "success");
      setAllNotifications((prev) =>
        prev.map((el) => {
          if (el._id == id) return data?.data;
          return el;
        })
      );
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };
  useEffect(() => {
    getAllNotification();
  }, []);
  return (
    <>
      {allNotifications.map((notification) => (
        <div
          key={notification?._id}
          className="w-full flex flex-col bg-white rounded-lg shadow p-4 "
        >
          <div className="flex flex-row justify-between">
            <div>
              <p className="block text-cyan-600 text-sm font-medium	">
                {moment(notification?.scheduleDateTime).format(
                  "h:mm A D MMMM YYYY"
                )}
              </p>
              <h6 className="text-[#093147] publicSans text-lg font-semibold">
                {notification?.title}✨
              </h6>
            </div>
            <div className="flex gap-2">
              <button
                className={`w-36 border-2 border-solid rounded-lg px-2 ${
                  notification?.sent
                    ? "bg-green-100  border-green-500"
                    : "bg-yellow-100  border-yellow-500"
                } `}
              >
                {notification?.sent ? "Pushed" : "Scheduled"}
              </button>
              <button className="w-36 bg-cyan-100  border-cyan-500 border-2 border-solid rounded-lg px-4 py-1">
                {"Notification"}
              </button>
            </div>
          </div>
          <div className="min-h-[80px] py-2 mr-20 text-gray-700 tex">
            {notification?.description}
          </div>
          {!notification?.sent && (
            <div className="flex justify-end">
              {notification?.cancel ? (
                <p className="bg-red-200 text-gray-100 border-red-500 border-2 border-solid rounded-3xl px-6 py-2">
                  Cancelled
                </p>
              ) : (
                <button
                  className="bg-red-200 text-gray-100 border-red-500 border-2 border-solid rounded-3xl px-6 py-2"
                  onClick={() => cancelNotification(notification?._id)}
                >
                  Cancel
                </button>
              )}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default ListofNotifications;
