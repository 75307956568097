export function registerServiceWorker() {

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .register('/firebase-messaging-sw.js')
            .then((registration) => {
                console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch((err) => {
                console.error('Service Worker registration failed:', err);
            });
    } else {
        console.warn('Service Workers are not supported in this browser.');
    }
}