import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
// Table Imports
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useAppSelector } from "../../../store/hooks";
import moment from "moment";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "white",
  borderRadius: "12px",
  border: "1px solid #fff",
  p: 2,
  // boxShadow: 24,
  // p: 4,
};

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: "Public Sans",
  fontSize: "14px",
  lineHeight: "20px",
  borderBottom: "none",
  color: "#1D1F2C",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontFamily: "Public Sans",
  fontSize: "14px",
  fontWeight: 500, // Customize cell text styling
  lineHeight: "20px",
  borderBottom: "none",
  color: "#667085",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#fff", // Customize table head row background color
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const HistoryTable = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [history, setHistory] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [dataRange, setDataRange] = useState("");
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const { user } = useAppSelector((state) => state?.user);
  const [selected, setSelected] = useState(null);

  const getAllHistory = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `dashboard/dashUser/getAdoptionHistory?page=${page}&limit=10&id=${user?._id}`
      );
      console.log(data);

      setHistory(data?.data);
      setCount(data?.totalData);
      setTotalPages(data?.totalPage);
      // setDataRange(
      //   "Showing " +
      //     (data?.currentPage * 10 - 9) +
      //     "-" +
      //     data?.currentPage * 10 +
      //     " of " +
      //     data?.totalData
      // );
      const startRange = data?.currentPage * 10 - 9;
      const endRange =
        data?.currentPage === data?.totalPage
          ? data?.totalData
          : data?.currentPage * 10;

      setDataRange(`Showing ${startRange}-${endRange} of ${data?.totalData}`);
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllHistory();
  }, [page]);
  console.log(
    "age",
    selected?.pet?.breed,
    "profile image",
    selected?.user?.profilePicture
  );

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex flex-col gap-2">
          <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
            Adoption History
          </h6>
          <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
            Pet Availability
          </p>
        </div>
        <div className="w-full bg-white rounded-lg shadow flex flex-col py-2 gap-6 px-4 ">
          <div className="w-full">
            <Table
              sx={{
                borderCollapse: "separate !important",
                width: "100%",
              }}
            >
              <TableHead>
                <CustomTableHeadRow>
                  <CustomTableHeadCell>Pet Name</CustomTableHeadCell>
                  <CustomTableHeadCell>Owner Name</CustomTableHeadCell>
                  <CustomTableHeadCell>Adoption Date</CustomTableHeadCell>
                  <CustomTableHeadCell>Follow Up</CustomTableHeadCell>
                </CustomTableHeadRow>
              </TableHead>
              <TableBody>
                {history.map((his: any) => {
                  return (
                    <CustomTableBodyRow key={his?._id}>
                      <CustomTableBodyCell>
                        <div className="w-full flex gap-2 items-center ">
                          <img
                            src={
                              his?.pet?.profilePicture.find(
                                (pic) => pic?.isBestImage
                              )?.url || his?.pet?.profilePicture[0]?.url
                            }
                            alt={his?.pet?.name}
                            className="rounded-lg w-8 h-8"
                          />
                          <p className="">{his?.pet?.name}</p>
                        </div>
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {his?.user?.fullname}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {moment(his?.createdAt).format("DD MM YYYY")}
                      </CustomTableBodyCell>

                      <CustomTableBodyCell>
                        <button
                          onClick={() => {
                            setOpenTransferModal(true);

                            setSelected(his);
                          }}
                          className="text-secondary publicSans text-sm font-medium leading-5 bg-[#E5F7FF]  py-1 px-2 rounded "
                        >
                          Details
                        </button>
                      </CustomTableBodyCell>
                    </CustomTableBodyRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <div className="w-full flex justify-between items-center p-4">
            <p className="publicSans text-sm font-medium text-[#667085] leading-5">
              {dataRange}
            </p>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              color="secondary"
              onChange={(e, value) => setPage(value)}
            />
          </div>
        </div>
      </div>
      <Modal
        open={openTransferModal}
        onClose={() => setOpenTransferModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="w-full bg-white flex flex-col gap-6 relative p-2 ">
            <div className="absolute rounded-full bg-[#F2F2F2] p-3 w-fit right-0 -top-2 ">
              <img
                src="/assets/icons/cross.svg"
                alt="close"
                className="cursor-pointer"
                onClick={() => setOpenTransferModal(false)}
              />
            </div>
            <h5 className="publicSans text-xl font-semibold leading-7 text-center  ">
              Pet History
            </h5>
            <div className="w-full flex flex-col gap-4">
              <div className="flex gap-3 items-center">
                <img
                  src={selected?.pet.profilePicture[0]?.url}
                  alt={selected?.pet?.name}
                  className="rounded-full h-12 w-12"
                />
                <div className="flex flex-col items-start gap-1">
                  <p className="publicSans text-base font-semibold leading-5 text-blackTheme">
                    {selected?.pet?.name}
                  </p>
                  <p className="publicSans text-sm font-semibold leading-5 text-gray-500">
                    {selected?.pet?.breed}
                  </p>
                  <p className="publicSans text-xs leading-5 text-[#8CC8E4]">
                    {moment().diff(selected?.pet?.dob, "years")} years
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col gap-2  ">
              <p className="publicSans text-sm text-gray-500 leading-5">
                Owner
              </p>
              <div className="flex gap-3 items-center ">
                <img
                  src={selected?.user?.profilePicture}
                  alt={selected?.user?.fullname}
                  className="rounded-full h-12 w-12"
                />
                <div className="flex flex-col items-start gap-1">
                  <p className="publicSans text-base font-semibold leading-5 text-blackTheme">
                    {selected?.user?.fullname}
                  </p>
                  <p className="publicSans text-sm font-semibold leading-5 text-gray-500">
                    {selected?.user?.username}
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div className="w-full flex justify-end gap-4">
              <button
                onClick={() => setOpenTransferModal(false)}
                className="px-3 py-2 border border-[#858D9D] text-[#858D9D] rounded-lg publicSans text-sm font-semibold leading-5 "
              >
                Cancel
              </button>
              <button className="px-3 py-2 border border-[#1F9CB9] bg-[#1F9CB9] text-white  rounded-lg publicSans text-sm font-semibold leading-5 ">
                Export History
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default HistoryTable;
