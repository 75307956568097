/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Loading from 'react-fullscreen-loading';
import { Modal } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { callAxios } from '../../../utils/axios';
import { styled } from '@mui/material/styles';
import { toastMessage } from '../../../utils/toast';
import { useNavigate } from 'react-router';
import AssignVan from './Common/AssignVan';
import AssignNewTime from './Common/AssignNewTime';

// Table Imports
const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff', // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: 'Public Sans',
  fontSize: '14px',
  lineHeight: '20px',
  borderBottom: 'none',
  color: '#1D1F2C',
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff', // Customize cell background color
  fontFamily: 'Public Sans',
  fontSize: '14px',
  fontWeight: 500, // Customize cell text styling
  lineHeight: '20px',
  borderBottom: 'none',
  color: '#667085',
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#fff', // Customize table head row background color
  border: 'none',
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: '10px',
}));

const GrommingManagementTable = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [petGrooming, setPetGrooming] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [dataRange, setDataRange] = useState('');
  const [assignVan, setAssignVan] = useState(false);
  const [giveNewTime, setGiveNewTime] = useState(false);


  const acceptRequest = async (request) => {
    try {
      setLoading(true);
      await callAxios('put', `dashboard/grooming/pet-grooming/${request._id}`, {
        vanId: (request as any)?._id,
        status: "accepted"
      });
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
    finally {
      getAllPetGrooming();
    }
  }

  const getAllPetGrooming = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/grooming/all-pet-grooming?page=${page}&limit=10&search=${searchText}`
      );
      console.log(data);

      setPetGrooming(data?.data);
      setCount(data?.totalData);
      setTotalPages(data?.totalPages);
      setDataRange(
        'Showing' +
        (data?.currentPage * 10 - 9) +
        '-' +
        data?.currentPage * 10 +
        ' of ' +
        data?.totalData
      );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };

  const rejectRequest = async (request) => {
    try {
      setLoading(true);
      await callAxios('put', `dashboard/grooming/pet-grooming/${request._id}`, {
        vanId: (request as any)?._id,
        status: "rejected"
      });
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
    finally {
      getAllPetGrooming();
    }
  }
  useEffect(() => {
    getAllPetGrooming();
  }, [page, searchText]);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex flex-col gap-2">
          <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
            Mobile Grooming Management
          </h6>
          <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
            Mobile Grooming Management
          </p>
        </div>
        <div className="w-full bg-white rounded-lg flex justify-between items-center shadow p-4 px-8 ">
          <div className="w-1/2 flex items-center rounded-full bg-[#F9F9F9] p-2 px-4 ">
            <img src="/assets/icons/search.svg" alt="" className="" />
            <input
              type="text"
              placeholder="Search"
              className="w-full bg-transparent outline-none ml-2 text-gray-500 text-sm "
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <div className="w-full bg-white rounded-lg shadow flex flex-col py-2 gap-6 px-4 ">
          <div className="w-full">
            <Table
              sx={{
                borderCollapse: 'separate !important',
                width: '100%',
              }}
            >
              <TableHead>
                <CustomTableHeadRow>
                  <CustomTableHeadCell>Submit date & time</CustomTableHeadCell>
                  <CustomTableHeadCell>Appt date & time</CustomTableHeadCell>
                  <CustomTableHeadCell>Client name</CustomTableHeadCell>
                  <CustomTableHeadCell>Pet</CustomTableHeadCell>
                  <CustomTableHeadCell>Location</CustomTableHeadCell>
                  <CustomTableHeadCell>Services</CustomTableHeadCell>
                  <CustomTableHeadCell>Amount</CustomTableHeadCell>
                  <CustomTableHeadCell>Driver</CustomTableHeadCell>
                  <CustomTableHeadCell>Payment Status</CustomTableHeadCell>
                  <CustomTableHeadCell>Status</CustomTableHeadCell>
                  <CustomTableHeadCell>Action</CustomTableHeadCell>
                </CustomTableHeadRow>
              </TableHead>
              <TableBody>
                {petGrooming.map((pet: any) => {
                  return (
                    <CustomTableBodyRow key={pet?._id}>

                      <CustomTableBodyCell>
                        {Date.parse(pet?.createdAt) ? moment(pet?.createdAt).format('DD-MM-YYYY hh:mm A') : ''}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {pet?.time}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {pet?.user?.fullname}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {pet?.petId?.name}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        <a className='underline' href={`https://www.google.com/maps/@${pet?.location.latitude},${pet?.location.longitude},17z`} target="_blank" rel="noreferrer">
                          {`(${pet?.location.latitude + ', ' + pet?.location.longitude})`}
                        </a>
                      </CustomTableBodyCell>

                      <CustomTableBodyCell>
                        <div className='text-center'>
                          {pet?.services.map(service => service.name).join(', ')}
                        </div>
                      </CustomTableBodyCell>

                      <CustomTableBodyCell>
                        <div className='text-center'>
                          {pet?.amount}
                        </div>
                      </CustomTableBodyCell>

                      <CustomTableBodyCell>
                        <div className='text-center'>
                          {pet?.vanId?.driverId?.username ? pet?.vanId?.driverId?.username : "-"}
                        </div>
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {pet?.paymentConfirmed ? "Paid" : "Pending"}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {pet?.status}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>


                        {pet?.paymentConfirmed ? <button className="text-white publicSans text-sm font-medium lfeading-5 bg-[#1F9CB9] py-1 px-2 rounded"
                          onClick={() => {
                            setAssignVan(true);
                            setSelectedBooking(pet);
                          }}
                        >
                          Assign Van
                        </button> : pet?.status !== 'rejected' && <>

                          {pet?.status == 'pending' && <>
                            <button
                              onClick={() => {
                                acceptRequest(pet)
                              }}
                              className="text-white publicSans text-sm font-medium leading-5 bg-green-600 py-1 px-2 rounded "
                            >
                              Accept
                            </button>
                            <button
                              className="text-white publicSans text-sm font-medium leading-5 bg-red-600 py-1 px-2 rounded "
                              onClick={() => { rejectRequest(pet) }}
                            >
                              Reject
                            </button>
                          </>}

                          {pet?.status == 'pending' && <button className="text-white publicSans text-sm font-medium lfeading-5 bg-[#1F9CB9] py-1 px-2 rounded"
                            onClick={() => {
                              setGiveNewTime(true);
                              setSelectedBooking(pet);
                            }}
                          >
                            Give New Time
                          </button>}
                        </>}
                      </CustomTableBodyCell>
                    </CustomTableBodyRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <div className="w-full flex justify-between items-center p-4">
            <p className="publicSans text-sm font-medium text-[#667085] leading-5">
              {dataRange}
            </p>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              color="secondary"
              onChange={(e, value) => setPage(value)}
            />
          </div>
        </div>
      </div>

      <Modal
        open={assignVan}
        onClose={() => setAssignVan(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AssignVan
          booking={selectedBooking}
          setOpen={(value) => {
            setAssignVan(value)
            getAllPetGrooming()
          }}
        />
      </Modal>
      <Modal
        open={giveNewTime}
        onClose={() => setGiveNewTime(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AssignNewTime booking={selectedBooking}
          setOpen={(value) => {
            setGiveNewTime(value)
            getAllPetGrooming()
          }} />


      </Modal>
    </>
  );
};

export default GrommingManagementTable;