import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";

// Firebase configuration object
const firebaseConfig = {
    apiKey: "AIzaSyBZSFLx5J9fD4ezx650wiAd722V-zLJKEI",
    authDomain: "petrepublic-97618.firebaseapp.com",
    projectId: "petrepublic-97618",
    storageBucket: "petrepublic-97618.appspot.com",
    messagingSenderId: "1051091961551",
    appId: "1:1051091961551:web:731d516eecaa4cfb7899ba"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Check if FCM is supported before initializing messaging
let messaging;
isSupported().then((supported) => {
    if (supported) {
        // FCM is supported, proceed with Firebase Messaging Initialization
        messaging = getMessaging(app);
        console.log('Firebase Cloud Messaging is supported in this browser.');
    } else {
        console.warn('Firebase Cloud Messaging is not supported in this browser.');
    }
}).catch(err => {
    console.error('Error checking Firebase Cloud Messaging support:', err);
});

// Request Notification Permission
export const requestNotificationPermission = async () => {
    try {
        console.log('Requesting permission...');
        const permission = await Notification.requestPermission();
        console.log('Notification permission is ', permission);
        return permission;
    } catch (err) {
        console.error('Error requesting notification permission: ', err);
    }
};

// Get FCM Registration Token
export const getRegistrationToken = async (setTokenFound) => {
    if (!messaging) {
        console.warn('Messaging is not initialized because FCM is not supported.');
        setTokenFound(false);
        return;
    }

    try {
        const vapidKey = "BJkoHvd4iQOIFZpe3G7Ul6q9zDAhYTHdrmM_ebvPBNyKO88uIe7MoHmrzjCHjXk34_hlf_2eW9A3xwy-k5Cyt2Q";
        const currentToken = await getToken(messaging, { vapidKey });

        if (currentToken) {
            console.log('Current token for client: ', currentToken);
            setTokenFound(true);
            return currentToken;
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
        }
    } catch (error) {
        console.error('Error retrieving token: ', error);
    }
};

// Generate Token
export const generateToken = async (setTokenFound) => {
    const permission = await requestNotificationPermission();
    
    if (permission === 'granted') {
        return getRegistrationToken(setTokenFound);
    } else {
        console.log("Permission was denied. Current Notification Permission is: ", permission);
        setTokenFound(false);
    }
};

// Listen for Messages
export const onMessageListener = () =>
    new Promise((resolve, reject) => {
        if (!messaging) {
            console.warn('Messaging is not initialized because FCM is not supported.');
            reject('Messaging not supported');
        } else {
            onMessage(messaging, (payload) => {
                resolve(payload);
            });
        }
    });
