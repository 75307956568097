/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import Loading from "react-fullscreen-loading";
import Modal from "@mui/material/Modal";
import Pagination from "@mui/material/Pagination";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { callAxios } from "../../../utils/axios";
// Table Imports
import { styled } from "@mui/material/styles";
import { toastMessage } from "../../../utils/toast";
import { useNavigate } from "react-router";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: "Public Sans",
  fontSize: "14px",
  lineHeight: "20px",
  borderBottom: "none",
  color: "#1D1F2C",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontFamily: "Public Sans",
  fontSize: "14px",
  fontWeight: 500, // Customize cell text styling
  lineHeight: "20px",
  borderBottom: "none",
  color: "#667085",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#fff", // Customize table head row background color
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const ProductsTable = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [userType, setUserType] = useState("");
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [dataRange, setDataRange] = useState("");
  const [addProductModal, setAddProductModal] = useState(false);
  const [editProductModal, setEditProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [productCategories, setProductCategories] = useState([]);

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `dashboard/products/getAllProducts?page=${page}&limit=10&search=${searchText}`
      );
      console.log(data);

      setProducts(data?.data);
      setCount(data?.totalData);
      setTotalPages(data?.totalPage);
      setDataRange(
        "Showing" +
          (data?.currentPage * 10 - 9) +
          "-" +
          data?.currentPage * 10 +
          " of " +
          data?.totalData
      );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };
  const getAllProductsCategories = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `dashboard/products/getAllCategory?page=${page}&limit=10&search=${searchText}`
      );
      console.log("category",data);
      
      setProductCategories(
        data.data.map((el) => {
          return { id: el._id, label: el.name };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };
  const handleEnableDisable = async (event, id: any) => {
    console.log(event.target.checked, id);

    try {
      setLoading(true);
      const { data } = await callAxios(
        "put",
        `dashboard/dashUser/updateUser?id=${id}`,
        {
          active: event.target.checked,
        }
      );
      toastMessage(
        `Products ${
          event.target.checked ? "Disabled" : "Enabled"
        } successfully`,
        "success"
      );
      setProducts((prev) =>
        prev.map((user) => {
          if (user._id == id) return data?.data;
          else return user;
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllProducts();
  }, [page, searchText, userType]);

  useEffect(() => {
    getAllProductsCategories();
  }, []);

  // console.log("product category",productCategories);
  
  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />

      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex flex-col gap-2">
          <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
            Products
          </h6>
          <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
            Products
          </p>
        </div>
        <div className="w-full flex flex-row justify-between bg-white rounded-lg shadow p-4 px-8 ">
          <div className="w-10/12 flex items-center rounded-full bg-[#F9F9F9] p-2 px-4 ">
            <img src="/assets/icons/search.svg" alt="" className="" />
            <input
              type="text"
              placeholder="Search"
              className="w-full bg-transparent outline-none ml-2 text-gray-500 text-sm "
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className="flex  justify-center items-center">
            <button
              className="bg-cyan-800 text-white border-cyan-800 border-2 border-solid rounded-3xl px-6 py-2"
              onClick={() => setAddProductModal(true)}
            >
              Add Products
            </button>
          </div>
        </div>
        <div className="w-full bg-white rounded-lg shadow flex flex-col py-2 gap-6 px-4 ">
          <div className="w-full">
            <Table
              sx={{
                borderCollapse: "separate !important",
                width: "100%",
              }}
            >
              <TableHead>
                <CustomTableHeadRow>
                  <CustomTableHeadCell></CustomTableHeadCell>
                  <CustomTableHeadCell>Product Name</CustomTableHeadCell>
                  <CustomTableHeadCell>Description</CustomTableHeadCell>
                  <CustomTableHeadCell>Price</CustomTableHeadCell>
                  <CustomTableHeadCell>Edit</CustomTableHeadCell>
                  {/* <CustomTableHeadCell>
                    <div className="flex justify-center">Enable/Disable</div>
                  </CustomTableHeadCell> */}
                </CustomTableHeadRow>
              </TableHead>
              <TableBody>
                {products.map((product: any) => {
                  return (
                    <CustomTableBodyRow key={product._id}>
                      <CustomTableBodyCell>
                        <img
                          src={
                            product?.images?.length > 0
                              ? product?.images[0]
                              : "/assets/images/user-logo.png"
                          }
                          alt=""
                          className="w-8 h-8 rounded-full"
                        />
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>{product.name}</CustomTableBodyCell>
                      <CustomTableBodyCell className="w-36">
                        {product.description.length>130 ? product.description.substring(0,130)+"..." : product.description}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>{product.price}</CustomTableBodyCell>

                      <CustomTableBodyCell>
                        <button
                          onClick={() => {
                            setSelectedProduct(product);
                            setEditProductModal(true);
                          }}
                          className="text-white publicSans text-sm font-medium leading-5 bg-[#1F9CB9] py-1 px-2 rounded "
                        >
                          Edit
                        </button>
                      </CustomTableBodyCell>
                      {/* <CustomTableBodyCell>
                        <div className="flex justify-center">
                          <Switch
                            checked={product?.active}
                            onChange={(e) =>
                              handleEnableDisable(e, product?._id)
                            }
                            inputProps={{ "aria-label": "controlled" }}
                            color="secondary"
                          />
                        </div>
                      </CustomTableBodyCell> */}
                    </CustomTableBodyRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <div className="w-full flex justify-between items-center p-4">
            <p className="publicSans text-sm font-medium text-[#667085] leading-5">
              {dataRange}
            </p>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              color="secondary"
              onChange={(e, value) => setPage(value)}
            />
          </div>
        </div>
        <Modal
          open={addProductModal}
          onClose={() => setAddProductModal(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <AddProduct
            setOpen={setAddProductModal}
            setProducts={setProducts}
            productCategories={productCategories}
          />
        </Modal>
        <Modal
          open={editProductModal}
          onClose={() => setEditProductModal(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <EditProduct
            setOpen={setEditProductModal}
            setProducts={setProducts}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            productCategories={productCategories}
          />
        </Modal>
      </div>
    </>
  );
};

export default ProductsTable;
