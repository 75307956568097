import {
  AutocompleteElement,
  FormContainer,
  RadioButtonGroup,
  SelectElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { CropperModal } from '../../../Common/ImageCropper';
import Loading from 'react-fullscreen-loading';
import { callAxios } from '../../../../utils/axios';
import { styled } from '@mui/material/styles';
import { toastMessage } from '../../../../utils/toast';
import { useAppSelector } from '../../../../store/hooks';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'white',
  borderRadius: '12px',
  border: '1px solid #fff',
  p: 2,
  // boxShadow: 24,
  // p: 4,
};

type FormProps = {
  petCategory: string;
  name: string;
  serviceType: string;
  serviceTypeCheck: string;
  description: string;
  price: string;
  priceMedium: string;
  priceLarge: string;
  priceExtraLarge: string;
  time: number;
  topCategories: string[];
  primaryService: string;
  // isMobileGrooming: boolean;
};
const styles = {
  root: {
    '& .MuiInputBase-input:-webkit-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input:-moz-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::-ms-input-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    backgroundColor: '#F9F9FC',
    borderRadius: '8px',
    color: '#858D9D !important',
  },
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const EditService = ({
  open,
  setOpen,
  existingData,
  checked,
  setChecked,
}: any) => {
  const [loading, setLoading] = useState(false);
  const { user } = useAppSelector((state) => state.user);

  const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
  const [petCategoryOptions, setPetCategoryOptions] = useState([]);
  const [primaryServices, setPrimaryServices] = useState([] as any);

  const [src, setSrc] = useState(null);
  const [preview, setPreview] = useState(null);
  const [image, setImage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(existingData?.isMobileGrooming ?? false)
  // handle Change
  const handleImgChange = (e) => {
    setSrc(URL.createObjectURL(e.target.files[0]));
    setModalOpen(true);
  };

  const getServiceType = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        'dashboard/services/getServiceType?page=1&limit=100'
      );

      console.log(data);

      setServiceTypeOptions(
        data?.data?.map((item: any) => {
          return {
            label: item.name,
            id: item._id,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };
  const getPetCategory = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        'app/pet/getPetCategory?page=1&limit=100'
      );

      console.log(data);

      setPetCategoryOptions(
        data?.data?.map((item: any) => {
          return {
            label: item.name,
            id: item._id,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };
  const getPrimaryServices = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        // "dashboard/services/getAllServices?page=1&limit=100&serviceTypeCheck=PRIMARY"
        `dashboard/services/getAllServices?page=1&limit=100&serviceTypeCheck=PRIMARY&vet=${user._id}`
      );

      console.log(data);

      setPrimaryServices(
        data?.data?.map((item: any) => {
          return {
            label: item.name,
            id: item._id,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };

  useEffect(() => {
    getServiceType();
    getPetCategory();
    getPrimaryServices();
  }, []);

  const defaultValues: FormProps = {
    // petTypes: existingData?.petTypes,
    petCategory: existingData?.petCategory?._id,

    name: existingData?.name,
    serviceType: existingData?.serviceType?._id,
    serviceTypeCheck: existingData?.serviceTypeCheck,
    description: existingData?.description,
    price: existingData?.price,
    priceMedium: existingData?.priceMedium,
    priceLarge: existingData?.priceLarge,
    priceExtraLarge: existingData?.priceExtraLarge,
    time: existingData?.time,
    // isMobileGrooming: existingData?.isMobileGrooming,
    topCategories: existingData?.topCategories?.map((item: any) => {
      return {
        label: item,
        id: item,
      };
    }),
    primaryService: existingData?.primaryService,
  };
  const [inputField, setInputField] = useState(defaultValues);
  const onInputChange = (e: any) => {
    console.log(e.target.value);

    const { name, value } = e.target;
    setInputField((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onInputSelectChange = (name, value: any) => {
    setInputField((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const checkBoxHandler = (event) => {
    setIsChecked(event.target.checked);
  };

  const onSubmit = async (values: FormProps) => {
    if (values.time === 0) {
      return toastMessage('Please select time', 'error');
    }

    try {
      setLoading(true);

      let result;

      if (image) {
        const formData = new FormData();
        formData.append('file', image);
        result = await callAxios('post', 'app/user/uploadImage', formData);
        console.log(result);
      }

      if (values.price === existingData?.price) {
        delete values.price;
      }
      if (values.priceMedium === existingData?.priceMedium) {
        delete values.priceMedium;
      }
      if (values.priceLarge === existingData?.priceLarge) {
        delete values.priceLarge;
      }
      if (values.priceExtraLarge === existingData?.priceExtraLarge) {
        delete values.priceExtraLarge;
      }

      const payload = {
        ...values,
        images: image ? [result?.data?.url] : existingData?.images,
        topCategories: values.topCategories.map((item: any) => item?.id),
        time: values.time * 60,
        isMobileGrooming: isChecked
      };
      const { data } = await callAxios(
        'put',
        `dashboard/services/updateService?id=${existingData?._id}&priceId=${existingData?.stripePriceId}&stripeProductId=${existingData?.stripeProductId}`,
        payload
      );
      console.log(data);
      setChecked(!checked);
      setOpen(false);
      toastMessage('Service Updated Successfully', 'success');
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };

  return (
    <Box sx={style}>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full bg-white flex flex-col gap-6 relative p-2 ">
        <div className="absolute rounded-full bg-[#F2F2F2] p-3 w-fit right-0 -top-2 ">
          <img
            src="/assets/icons/cross.svg"
            alt="close"
            className="cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>
        <h5 className="publicSans text-xl font-semibold leading-7 text-center  ">
          Edit Service
        </h5>
        <FormContainer
          // defaultValues={defaultValues} onSuccess={onSubmit}
          defaultValues={inputField}
          onSuccess={onSubmit}
          values={inputField}
          resetOptions={{
            keepValues: false,
            keepIsSubmitted: false,
            keepTouched: false,
            keepDirty: false,
            keepIsValid: false,
            keepSubmitCount: false,
          }}
          shouldFocusError={true}
        >
          {' '}
          <div className="w-full flex flex-col gap-4">
            <div className="w-full grid grid-cols-2 gap-4">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Pet Types
                </p>
                <SelectElement
                  name="petCategory"
                  value={inputField?.petCategory}
                  onChange={(value) =>
                    onInputSelectChange('petCategory', value)
                  }
                  fullWidth
                  size="small"
                  placeholder="Center Name"
                  sx={styles.root}
                  options={petCategoryOptions}
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Service Name
                </p>
                <TextFieldElement
                  name="name"
                  value={inputField?.name}
                  onChange={onInputChange}
                  fullWidth
                  size="small"
                  placeholder="Enter Service Name"
                  sx={styles.root}
                  required
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Service Type
                </p>
                {/* <SelectElement
                  name="serviceType"
                  fullWidth
                  size="small"
                  placeholder="Email"
                  sx={styles.root}
                  required
                  options={serviceTypeOptions}
                /> */}
                <RadioButtonGroup
                  onChange={(value) => {
                    setInputField((prev) => ({
                      ...prev,
                      serviceTypeCheck: value,
                    }));
                  }}
                  name="serviceTypeCheck"
                  options={[
                    {
                      id: 'PRIMARY',
                      label: 'Primary',
                    },
                    {
                      id: 'SECONDARY',
                      label: 'Secondary',
                    },
                  ]}
                  row
                  required
                />
              </div>
              {/* <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Service Price
                </p>
                <TextFieldElement
                  name="price"
                  fullWidth
                  size="small"
                  type="number"
                  placeholder="Enter Service Price"
                  sx={styles.root}
                />
              </div> */}
              <div className="w-full grid grid-cols-3 gap-4">
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Service Price for Small
                  </p>
                  <TextFieldElement
                    name="price"
                    value={inputField?.price}
                    onChange={onInputChange}
                    fullWidth
                    size="small"
                    type="number"
                    placeholder="Enter Service Price for Small"
                    sx={styles.root}
                    required
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Service Price for Medium
                  </p>
                  <TextFieldElement
                    name="priceMedium"
                    value={inputField?.priceMedium}
                    onChange={onInputChange}
                    fullWidth
                    size="small"
                    type="number"
                    placeholder="Enter Service Price"
                    sx={styles.root}
                    required
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Service Price For Large
                  </p>
                  <TextFieldElement
                    name="priceLarge"
                    value={inputField?.priceLarge}
                    onChange={onInputChange}
                    fullWidth
                    size="small"
                    type="number"
                    placeholder="Enter Service Price"
                    sx={styles.root}
                    required
                  />
                </div>
              </div>
              <div className="w-full grid grid-cols-3 gap-4">
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Service Price for Extra Large
                  </p>
                  <TextFieldElement
                    name="priceExtraLarge"
                    value={inputField?.priceExtraLarge}
                    onChange={onInputChange}
                    fullWidth
                    size="small"
                    type="number"
                    placeholder="Enter Service Price"
                    sx={styles.root}
                    required
                  />
                </div>
              </div>

              {inputField?.serviceTypeCheck == 'SECONDARY' && (
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Choose Primary Service
                  </p>
                  <SelectElement
                    name="primaryService"
                    value={inputField?.primaryService}
                    onChange={(value) =>
                      onInputSelectChange('primaryService', value)
                    }
                    fullWidth
                    size="small"
                    placeholder="Email"
                    sx={styles.root}
                    required
                    options={primaryServices}
                  />
                </div>
              )}
              <div className="flex flex-col col-span-2 gap-1 w-full ">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Service Description
                </p>
                <TextFieldElement
                  name="description"
                  fullWidth
                  size="small"
                  placeholder="Enter Service Description"
                  sx={styles.root}
                  required
                  multiline
                  rows={4}
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Time
                </p>
                <SelectElement
                  name="time"
                  fullWidth
                  size="small"
                  placeholder="Select Time"
                  sx={styles.root}
                  required
                  options={[
                    { label: '0.5 Hour', id: 0.5 },
                    { label: '1 Hour', id: 1 },
                    { label: '1.5 Hour', id: 1.5 },
                    { label: '2 Hour', id: 2 },
                    { label: '2.5 Hour', id: 2.5 },
                    { label: '3 Hour', id: 3 },
                    { label: '3.5 Hour', id: 3.5 },
                    { label: '4 Hour', id: 4 },
                    { label: '4.5 Hour', id: 4.5 },
                    { label: '5 Hour', id: 5 },
                    { label: '5.5 Hour', id: 5.5 },
                    { label: '6 Hour', id: 6 },
                  ]}
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Upload Service Image
                </p>
                <Button
                  className="bg-[#F0F7FC] p-2 text-secondary publicSans font-semibold leading-5 "
                  component="label"
                  variant="contained"
                  color="secondary"
                >
                  Add Image
                  <VisuallyHiddenInput
                    type="file"
                    required
                    accept="image/*"
                    onChange={handleImgChange}
                  />
                </Button>
              </div>
            </div>
            <div className="w-full grid grid-cols-2 gap-4">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Top Categories
                </p>
                <AutocompleteElement
                  name="topCategories"
                  required
                  multiple
                  autocompleteProps={{
                    sx: styles.root,
                    size: 'small',
                  }}
                  options={[
                    { label: 'Pet Grooming', id: 'Pet Grooming' },
                    { label: 'Pet Boarding', id: 'Pet Boarding' },
                    { label: 'Day Care', id: 'Day Care' },
                    { label: 'Mobile Pet Grooming', id: 'Mobile Pet Grooming' },
                  ]}
                />
              </div>
            </div>
            <div className="w-full flex justify-end pt-4">
              <button className="publicSans text-sm font-semibold leading-5 bg-[#EF8214] rounded-lg p-3 text-white">
                Edit Service
              </button>
            </div>
          </div>
        </FormContainer>
      </div>
      <CropperModal
        modalOpen={modalOpen}
        src={src}
        setImage={setImage}
        setPreview={setPreview}
        setModalOpen={setModalOpen}
      />
    </Box>
  );
};

export default EditService;