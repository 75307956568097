/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
// Table Imports
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import FinacialChart from "./FinacialChart";

import RevenueChart from "./RevenueChart";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: "Public Sans",
  fontSize: "14px",
  lineHeight: "20px",
  borderBottom: "none",
  color: "#1D1F2C",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontFamily: "Public Sans",
  fontSize: "14px",
  fontWeight: 500, // Customize cell text styling
  lineHeight: "20px",
  borderBottom: "none",
  color: "#667085",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#fff", // Customize table head row background color
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const FinancialManagement = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [userType, setUserType] = useState("");
  const [bookings, setBookings] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [dataRange, setDataRange] = useState("");
  const [dashboardDetails, setDashboardDetails] = useState({} as any);

  const getAllBookings = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", `dashboard/services/getBookings?page=${page}`);
      const { data: dashboardDetails } = await callAxios(
        "get",
        `dashboard/services/dashboardDetails`
      );
      console.log(dashboardDetails);

      setBookings(data?.data);
      setTotalPages(data?.totalPage);
      setDataRange(
        "Showing" +
          (data?.currentPage * 10 - 9) +
          "-" +
          data?.currentPage * 10 +
          " of " +
          data?.totalData
      );
      setDashboardDetails(dashboardDetails?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllBookings();
  }, [page, searchText, userType]);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex flex-row gap-4">
          <div className="w-1/2 bg-white rounded-lg shadow p-4  px-8 ">
            <div>
              <p className="text-black text-lg leading-5 tracking-wide publicSans font-medium">
                Statistic
              </p>
              <p className="text-gray-600 text-sm leading-5 tracking-wide publicSans font-medium py-2">
                Income and Expense
              </p>
            </div>
            <div className="flex flex-row flex-1 gap-2 justify-between py-4">
             

              <div>
                <div className="text-gray-600 publicSans">User Booking</div>
                <div className="text-black font-bold text-lg publicSans">
                  {dashboardDetails?.countBookings ?? 0}
                </div>
              </div>

              {/* <div>
                <div className="text-gray-600 publicSans">Payment Transaction</div>
                <div className="text-black font-bold text-lg publicSans">
                  {dashboardDetails?.countTransactions ?? 0}
                </div>
              </div> */}

              {/* <div>
                <div className="text-gray-600 publicSans">Product Sales</div>
                <div className="text-black font-bold text-lg publicSans">
                  {dashboardDetails?.countUsers ?? 0}
                </div>
              </div> */}
            </div>
            <div>
              <FinacialChart data={dashboardDetails?.monthlyData} />
            </div>
          </div>
          <div className="w-1/2 flex flex-col justify-between gap-6 bg-white rounded-lg shadow p-4 px-8 ">
            <div>
              <p className="text-black text-lg leading-5 tracking-wide publicSans font-medium">
                Total Revenue generated
              </p>
              <p className="text-gray-600 text-sm leading-5 tracking-wide publicSans font-medium py-2">
                Average Number{" "}
              </p>
            </div>
            <div className="">
              <RevenueChart data={dashboardDetails?.monthlyData} />
            </div>
          </div>
        </div>

        <div className="w-full bg-white rounded-lg shadow flex flex-col py-2 gap-6 px-4 ">
          <div className="w-full">
            <Table
              sx={{
                borderCollapse: "separate !important",
                width: "100%",
              }}
            >
              <TableHead>
                <CustomTableHeadRow>
                  <CustomTableHeadCell>Date</CustomTableHeadCell>
                  <CustomTableHeadCell>Booking Type</CustomTableHeadCell>
                  <CustomTableHeadCell>Services</CustomTableHeadCell>
                  <CustomTableHeadCell>Invoice No</CustomTableHeadCell>
                  <CustomTableHeadCell>Amount</CustomTableHeadCell>
                </CustomTableHeadRow>
              </TableHead>
              <TableBody>
                {bookings.map((user: any) => {
                  return (
                    <CustomTableBodyRow key={user?._id}>
                      <CustomTableBodyCell>
                        {moment(user?.appointmentTimesamp).format("DD/MM/YYYY")}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {user?.cancelled ? (
                          <span style={{ color: "red" }}>Cancelled</span>
                        ) : (
                          "Booked"
                        )}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {user.services
                          ?.map((service: any) => service.name)
                          .join(", ")}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {user?.userType}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {user?.amount_received}
                      </CustomTableBodyCell>
                    </CustomTableBodyRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <div className="w-full flex justify-between items-center p-4">
            <p className="publicSans text-sm font-medium text-[#667085] leading-5">
              {dataRange}
            </p>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              color="secondary"
              onChange={(e, value) => setPage(value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FinancialManagement;
