import React, { useState, useEffect } from "react";
import { Box, TextField } from "@mui/material";
import Loading from "react-fullscreen-loading";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import moment from "moment";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  borderRadius: "12px",
  border: "1px solid #fff",
  p: 2,
  // boxShadow: 24,
  // p: 4,
};

const CancelAppointment = ({ setOpen, selected, getAllAppointments }: any) => {
  const [loading, setLoading] = useState(false);

  const cancelAppointment = async (selected) => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `dashboard/services/cancelAppointmentById/${selected?._id}`
      );

      setOpen(false);
      getAllAppointments();
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };
  return (
    <Box sx={style}>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full bg-white  py-8 flex flex-col gap-6 relative">
        <div className="absolute right-0 top-0">
          <img
            src="/assets/icons/cross.svg"
            alt=""
            className="cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>
        <h5 className="text-center publicSans text-blackTheme text-xl font-semibold leading-6 tracking-tight">
          Are you sure to <br /> Cancel Appointment?
        </h5>
        <div className="flex w-full justify-center gap-4">
          <button
            className="bg-[#1F9CB9] rounded-lg py-2 px-6 text-white publicSans font-medium text-sm leading-5 "
            onClick={() => cancelAppointment(selected)}
          >
            Yes
          </button>
          <button
            className="bg-[#EF8214] rounded-lg py-2 px-6 text-white publicSans font-medium text-sm leading-5 "
            onClick={() => setOpen(false)}
          >
            No
          </button>
        </div>
      </div>
    </Box>
  );
};

export default CancelAppointment;
