import { Box, MenuItem, Select } from '@mui/material';
import {
    FormContainer,
} from 'react-hook-form-mui';
import React, { useEffect, useState } from 'react';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Loading from 'react-fullscreen-loading';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { callAxios } from '../../../../utils/axios';
import { toastMessage } from '../../../../utils/toast';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'white',
    borderRadius: '12px',
    border: '1px solid #fff',
    p: 2,
    // boxShadow: 24,
    // p: 4,
};

const styles = {
    root: {
        '& .MuiInputBase-input:-webkit-autofill-placeholder': {
            color: '#858D9D',
            backgroundColor: '#fff',
            fontSize: '14px',
            fontFamily: 'Public Sans',
            fontWeight: 400,
        },
        '& .MuiInputBase-input:-moz-autofill-placeholder': {
            color: '#858D9D',
            backgroundColor: '#fff',
            fontSize: '14px',
            fontFamily: 'Public Sans',
            fontWeight: 400,
        },
        '& .MuiInputBase-input::-ms-input-placeholder': {
            color: '#858D9D',
            backgroundColor: '#fff',
            fontSize: '14px',
            fontFamily: 'Public Sans',
            fontWeight: 400,
        },
        '& .MuiInputBase-input::placeholder': {
            color: '#858D9D',
            backgroundColor: '#fff',
            fontSize: '14px',
            fontFamily: 'Public Sans',
            fontWeight: 400,
        },
        width: '100%',
        backgroundColor: '#F9F9FC',
        borderRadius: '8px',
        color: '#858D9D !important',
    },
};

const AssignVan = ({ setOpen, booking }: any) => {

    const [loading, setLoading] = useState(false);
    const [vans, setVans] = useState([]);
    const [selectedVan, setSelectedVan] = useState({});

    const fetchVans = async () => {
        try {
            setLoading(true);
            const { data } = await callAxios(
                'get',
                `dashboard/van/getAllVans?page=1&limit=0&activeVans=true`,
            );
            setVans(data.data);
            setLoading(false);
        } catch (error) {
            toastMessage('error', 'Failed to fetch vans');
        }
    }

    useEffect(() => {
        fetchVans();
    }, [])

    const handleVanChange = (e) => {
        setSelectedVan(e.target.value);

    }
    const handleVanSubmit = async () => {
        setLoading(true);
        try {
            if (!selectedVan) {
                toastMessage('error', 'Please select a van');
                return;
            }
            await callAxios('put', `dashboard/grooming/pet-grooming/${booking._id}`, {
                vanId: (selectedVan as any)?._id,
                status: "assigned"
            });
            toastMessage('success', 'Van assigned successfully');
            setOpen(false);
            fetchVans();
        } catch (err) {
            setLoading(false);
            toastMessage('error', err);
        }

    }

    console.log("======>>>>", selectedVan)

    return (
        <>
            <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
            <Box sx={style}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <FormContainer>
                        <div className="w-full bg-white flex flex-col gap-6 ">
                            <div className="w-full justify-between flex items-center">
                                <h6 className="publicSans text-xl leading-8 font-semibold text-blackTheme">
                                    Select Van
                                </h6>
                                <img
                                    src="/assets/icons/cross.svg"
                                    alt=""
                                    className="cursor-pointer"
                                    onClick={() => setOpen(false)}
                                />
                            </div>
                            <div className="w-full gap-1">
                                {/* Dropdown to select van */}
                                <Select
                                    name='selectedVan'
                                    label="Select Driver"
                                    sx={styles.root}
                                    value={selectedVan}
                                    onChange={handleVanChange}
                                >
                                    {vans.map((van) => (
                                        <MenuItem key={van._id} value={van} className='cursor-pointer'>
                                            {`${van?.driverId?.username} (${van.vehicleNumber})`}
                                        </MenuItem>
                                    ))
                                    }

                                </Select>

                            </div>


                            {/* CTA */}
                            <div className="w-full justify-between items-center flex">
                                {/* Cancel Button */}
                                <button
                                    className="px-3 py-2 rounded-lg border border-[#858D9D] text-[#858D9D] publicSans text-sm font-semibold leading-5 cursor-pointer "
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                {/* Save Button */}
                                <button
                                    className="px-3 py-2 rounded-lg border border-secondary text-white bg-secondary publicSans text-sm font-semibold leading-5 cursor-pointer "
                                    onClick={handleVanSubmit}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </FormContainer>
                </LocalizationProvider>
            </Box>
        </>
    );
};

export default AssignVan;