import React, { useState, useEffect } from "react";
import { Box, TextField } from "@mui/material";
import Loading from "react-fullscreen-loading";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import moment from "moment";
import { styled } from "@mui/material/styles";

import {
  FormContainer,
  TextFieldElement,
  SelectElement,
  DatePickerElement,
} from "react-hook-form-mui";
import Button from "@mui/material/Button";
import PetVaccine from "./PetVaccine";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "white",
  borderRadius: "12px",
  border: "1px solid #fff",
  p: 2,
  overflowY:'scroll',
  maxHeight:'90vh'
  // boxShadow: 24,
  // p: 4,
};

const styles = {
  root: {
    "& .MuiInputBase-input:-webkit-autofill-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input:-moz-autofill-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::-ms-input-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    backgroundColor: "#F9F9FC",
    borderRadius: "8px",
    color: "#858D9D !important",
  },
};

type FormProps = {
  name: string;
  breed: string;
  microchipNumber: string;
  dob: string;
  weight: number;
  gender: string;
  petCategory: string;
};

const PetDetails = ({ setOpen, existingData, checked, setChecked }: any) => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  // console.log(existingData);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [previewImages, setPreviewImages] = useState(
    existingData?.profilePicture?.map((item: any) => item.url)
  );
  const [images, setImages] = useState([]);

  const handleImageChange = (e: any) => {
    setImages(e.target.files);
    let images = [];
    let preview = [];
    for (const image of e.target.files) {
      preview.push(URL.createObjectURL(image));
    }
    setPreviewImages(preview);
  };

  // const onSubmit = async (values: FormProps) => {
  //   console.log(values);

  //   try {
  //     setLoading(true);
  //     let imagesUrls = [];
  //     if (images.length > 0) {
  //       for (const image of images) {
  //         const formData = new FormData();
  //         formData.append("file", image);
  //         const { data } = await callAxios(
  //           "post",
  //           "app/user/uploadImage",
  //           formData
  //         );
  //         imagesUrls.push({
  //           url: data?.url,
  //           isBestImage: false,
  //           active: true,
  //         });
  //       }
  //     } else {
  //       imagesUrls = previewImages;
  //     }

  //     imagesUrls[0].isBestImage = true;
  //     const { data } = await callAxios(
  //       "put",
  //       `dashboard/pet/updatePet?id=${existingData?._id}`,
  //       {
  //         ...values,
  //         profilePicture: imagesUrls,
  //       }
  //     );
  //     // console.log(data);
  //     toastMessage("Pet Updated Successfully", "success");
  //     setOpen(false);
  //     setChecked(!checked);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //     toastMessage(
  //       error?.response?.data?.message || "Something went wrong.",
  //       "error"
  //     );
  //   }
  // };

  const onSubmit = async (values: FormProps) => {
    console.log(values);

    try {
      setLoading(true);
      let imagesUrls = [];
      if (images.length > 0) {
        for (const image of images) {
          const formData = new FormData();
          formData.append("file", image);
          const { data } = await callAxios(
            "post",
            "app/user/uploadImage",
            formData
          );
          imagesUrls.push({
            url: data?.url,
            isBestImage: false,
            active: true,
          });
        }
      } else {
        imagesUrls = previewImages.map((url) => ({
          url,
          isBestImage: false,
          active: true,
        }));
      }

      if (imagesUrls.length > 0) imagesUrls[0].isBestImage = true;

      const { data } = await callAxios(
        "put",
        `dashboard/pet/updatePet?id=${existingData?._id}`,
        {
          ...values,
          profilePicture: imagesUrls,
        }
      );
      toastMessage("Pet Updated Successfully", "success");
      setOpen(false);
      setChecked(!checked);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const defaultValues = {
    name: existingData?.name || "",
    breed: existingData?.breed || "",
    microchipNumber: existingData?.microchipNumber || "",
    // dob: moment(existingData?.dob) || "",
    dob: moment(existingData?.dob).format("YYYY-MM-DD") || "",
    weight: existingData?.weight || "",
    gender: existingData?.gender,
    petCategory: existingData?.petCategory || "",
  };

  // const getPetCategories = async () => {
  //   try {
  //     setLoading(true);
  //     const { data } = await callAxios("get", "app/pet/getPetCategory");
  //     console.log(data);
  //     setCategoryOptions(
  //       data.data.map((item: any) => ({ id: item._id, label: item.name }))
  //     );
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //     toastMessage(
  //       error?.response?.data?.message || "Something went wrong.",
  //       "error"
  //     );
  //   }
  // };
  const getPetCategories = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", "app/pet/getPetCategory");
      console.log(data);
      setCategoryOptions(
        data.data.map((item: any) => ({ id: item._id, label: item.name }))
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPetCategories();
  }, []);

  return (
    <Box sx={style}>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />

      <div className="w-full bg-white flex flex-col gap-6 ">
        <div className="w-full justify-between flex items-center">
          <div className=""></div>
          <h6 className="publicSans text-xl leading-8 font-semibold text-blackTheme">
            Details View
          </h6>
          <img
            src="/assets/icons/cross.svg"
            alt=""
            className="cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>
        <div className="w-full flex justify-center items-center">
          <div className="flex gap-4 rounded-full bg-[#F0F7FC] ">
            <div
              onClick={() => setStep(1)}
              className={`py-2 cursor-pointer px-3 rounded-full publicSans text-sm font-medium leading-5 ${
                step === 1 ? "bg-[#8CC8E4] text-white" : "text-[#667085]"
              } `}
            >
              Pet Passport
            </div>
            <div
              onClick={() => setStep(2)}
              className={`py-2 cursor-pointer px-3 rounded-full   publicSans text-sm font-medium leading-5 ${
                step === 2 ? "bg-[#8CC8E4] text-white" : "text-[#667085]"
              }`}
            >
              Vaccine History
            </div>
          </div>
        </div>
        {step === 1 && (
          <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
            <div className="w-full flex flex-col gap-4">
              <div className="w-full grid grid-cols-2 gap-8 ">
                <div className="w-full flex flex-col gap-1">
                  <p className="publicSans text-sm font-medium text-[#777980]">
                    Pet Type
                  </p>
                  <SelectElement
                    name="petCategory"
                    fullWidth
                    size="small"
                    options={categoryOptions}
                    // placeholder="Ex-Labrador"
                    sx={styles.root}
                    required
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="publicSans text-sm font-medium text-[#777980]">
                    Pet Name
                  </p>
                  <TextFieldElement
                    name="name"
                    fullWidth
                    size="small"
                    placeholder="Ex-Tom"
                    sx={styles.root}
                    required
                  />
                </div>
              </div>
              <div className="w-full grid grid-cols-2 gap-8 ">
                <div className="w-full flex flex-col gap-1">
                  <p className="publicSans text-sm font-medium text-[#777980]">
                    Microchip Number
                  </p>
                  <TextFieldElement
                    name="microchipNumber"
                    fullWidth
                    size="small"
                    placeholder="#"
                    sx={styles.root}
                    required
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="publicSans text-sm font-medium text-[#777980]">
                    Breed
                  </p>
                  <TextFieldElement
                    name="breed"
                    fullWidth
                    size="small"
                    placeholder="Ex-Labrador"
                    sx={styles.root}
                    required
                  />
                </div>
              </div>
              <div className="w-full grid grid-cols-2 gap-8 ">
                <div className="w-full flex flex-col gap-1">
                  <p className="publicSans text-sm font-medium text-[#777980]">
                    Date of Birth
                  </p>
                  {/* <DatePickerElement
                    name="dob"
                    inputProps={{
                      size: "small",
                      fullWidth: true,
                    }}
                    
                    // placeholder="Ex-Labrador"
                    sx={styles.root}
                    required
                  /> */}
                  <TextFieldElement
                    name="dob"
                    fullWidth
                    size="small"
                    placeholder="YYYY-MM-DD"
                    type="date"
                    sx={styles.root}
                    required
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="publicSans text-sm font-medium text-[#777980]">
                    Gender
                  </p>
                  <SelectElement
                    name="gender"
                    fullWidth
                    size="small"
                    options={[
                      { id: "MALE", label: "Male" },
                      { id: "FEMALE", label: "Female" },
                    ]}
                    // placeholder="Ex-Labrador"
                    sx={styles.root}
                    required
                  />
                </div>
              </div>
              <div className="w-full grid grid-cols-2 gap-4">
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Weight
                  </p>
                  <TextFieldElement
                    name="weight"
                    fullWidth
                    type="number"
                    size="small"
                    placeholder="Kg"
                    sx={styles.root}
                    required
                  />
                </div>
              </div>
              <div className="w-full flex flex-col gap-1 ">
                <h6 className="text-tertiary publicSans text-base font-semibold ">
                  Upload Photos
                </h6>
                <div className="w-full border border-dotted border-[rgb(224,226,231)] bg-[#F9F9FC] rounded-lg flex items-center justify-center py-6">
                  <div className="flex flex-col gap-2  items-center">
                    {previewImages.length > 0 ? (
                      <div className="flex gap-2 items-center">
                        {previewImages.map((image) => {
                          return (
                            <img
                              src={image}
                              alt=""
                              className="w-12 h-12 rounded"
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <div className="rounded-lg bg-[#8CC8E4] p-3 ">
                        <img
                          src="/assets/icons/picture.png"
                          alt="icon"
                          className="w-4 h-4"
                        />
                      </div>
                    )}

                    <p className="publicSans text-sm font-normal leading-5 text-gray-400">
                      Drag and drop image here, or click add image
                    </p>
                    <p className="publicSans text-sm font-normal leading-5 text-gray-400">
                      Max Size (5 mb)
                    </p>
                    <Button
                      className="bg-[#F0F7FC] p-2 text-secondary publicSans font-semibold leading-5 "
                      component="label"
                      variant="contained"
                      color="secondary"
                    >
                      Add Image
                      <VisuallyHiddenInput
                        type="file"
                        required
                        accept="image/*"
                        multiple
                        onChange={handleImageChange}
                      />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-end pt-4">
                <button
                  type="submit"
                  className="publicSans text-sm font-semibold leading-5 bg-secondary rounded-lg p-3 text-white"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </FormContainer>
        )}
        {step === 2 && <PetVaccine id={existingData?._id} />}
      </div>
    </Box>
  );
};

export default PetDetails;
